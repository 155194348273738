import React, { useState } from "react";
import { Link } from "react-router-dom";
import dna from "../images/dna.png";
import footerLogo from "../images/footer-logo.png";
import footer from "../images/footer.png";
import { useForm } from "react-hook-form";
import { axiosConfig } from "../axiosConfig";
import { Alert, Snackbar } from "@mui/material";


const Landing_Footer = () => {

        type FormValues = {
            name: string;
            phone: string;
            email: string;
            customer_inquiries_message: string;
        }
    
        const [successMessage, setSuccessMessage] = useState('');
        const [errorMessage, setErrorMessage] = useState('');
        const [openSnackbar, setOpenSnackbar] = useState(false); 
    
        const form = useForm<FormValues>({
            defaultValues: {
                name: '',
                phone: '',
                email: '',
                customer_inquiries_message: '',
            },
        });
    
        const showAlert = (message: string, type: 'success' | 'error') => {
            if (type === 'success') {
                setSuccessMessage(message);
                setErrorMessage('');
            } else if (type === 'error') {
                setErrorMessage(message);
                setSuccessMessage('');
            }
            
            setOpenSnackbar(true); 
    
            setTimeout(() => {
                setOpenSnackbar(false);
            }, 3000);
        };
    
        const { register, handleSubmit, reset, formState: { errors } } = form;
        const [disableSubmit, setDisableSubmit] = useState(false);
    
        const onSubmit = async (data: FormValues) => {
            try {
                setDisableSubmit(true);
                setSuccessMessage('');
                setErrorMessage('');
                const response = await axiosConfig.post('/api/customer_inquiries', {
                    "name": data.name,
                    "phone": data.phone,
                    "email": data.email,
                    "message": data.customer_inquiries_message,
                });
    
                if (response.data.status === "SUCCESS") {
                    setDisableSubmit(false);
                    showAlert('Your message has been sent successfully!', 'success');
                    reset();
                }
            } catch (error: any) {
                console.error("An error occurred:", error);
                showAlert('An error occurred while sending your message. Please try again.', 'error');
                setDisableSubmit(false);
            }
        };
    

    return(

<footer className="font-og">
<div>
    <div className="md:flex-row flex flex-col-reverse">
        <div className="bg-[#1D1D1D] py-[15vw] md:py-[5.25vw] p-[5.25vw] md:w-[60%] text-white">
            <a href="index.html">
                <img src={footerLogo} className="w-[25vw] md:w-[11.5vw]" alt="Footer logo"/>
            </a>

            <div className="mt-[12vw] md:mt-[4vw] md:px-[2vw]">
                <div className="flex gap-[20vw] md:gap-[10vw]">
                    <div>
                        <p className="text-[6vw] md:text-[2.5vw]">Features</p>
                        <ul className="text-[3.5vw] md:text-[1.235vw] font-[350] leading-[1.3] space-y-[1vw] md:space-y-[.35vw] mt-[2vw] md:mt-[1vw]">
                            <li><Link to={"/create-curation-request"}>Data Curation</Link></li>
                            <li><Link to={"/study-table"}>Data Sets</Link></li>
                            <li><Link to={"/metadata-visualization"}>Meta Data Visualization</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="mt-[12vw] md:mt-[7vw]">
                    <div className="flex flex-col-reverse md:flex-row md:items-end gap-[8vw]">
                        <div className="md:w-[20.5vw] w-[80vw] relative">
                            <img src={dna} className="absolute top-[.5vw] right-[.5vw] w-[30vw] md:w-[10vw] translate-x-1/2 -translate-y-1/2" alt="dna"/>
                            <div>
                                <div className="">
                                    <img src={footer} alt="footer"/>
                                </div>
                                <a href="https://us.strandls.com/" target="_blank" rel="noopener noreferrer"  className="flex items-center mt-[8vw] md:mt-[2vw]">
                                    <div className="flex items-center rounded-full border-[1.5px] border-[#FFFFFF] text-[#FFFFFF] h-[7vw] md:h-[2.65vw] bg-[#191919] font-og text-[3.05vw] md:text-[1.05vw] leading-[1] px-[3vw] py-[1vw] md:px-[1vw] md:py-[.65vw]">
                                        <p className="whitespace-nowrap">VISIT STRAND WEBSITE</p>
                                    </div>
                                    <div className="bg-[#FFFFFF] h-[1.5px] md:h-[2.5px] w-[7vw] md:w-[4.75vw]"></div>
                                    <div className="rounded-full aspect-square flex items-center justify-center bg-[#191919] border-[1.5px] border-[#FFFFFF] h-[7vw] md:h-[2.65vw]">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-white w-[3vw] md:w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                            <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                                <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                                <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                                <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                            </g>
                                        </svg>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="flex gap-[4vw] md:gap-[1.85vw] items-center">
                            <a href="https://www.linkedin.com/company/strand-life-sciences" target="_blank" rel="noopener noreferrer"  className="aspect-square w-[12vw] md:w-[4.25vw] rounded-full bg-[#272727] grid place-content-center">
                                <svg id="Group_35829" data-name="Group 35829" xmlns="http://www.w3.org/2000/svg" className="h-[4vw] md:h-[1.75vw]" viewBox="0 0 34.712 34.711">
                                    <path id="Path_459" data-name="Path 459" d="M0,5H7.762V28.864H0Zm28.906.28c-.082-.026-.161-.054-.247-.078q-.156-.035-.315-.061A6.957,6.957,0,0,0,26.951,5c-4.526,0-7.4,3.291-8.342,4.562V5H10.847V28.864H18.61V15.847s5.866-8.17,8.342-2.169V28.864h7.76V12.76a7.741,7.741,0,0,0-5.806-7.48Z" transform="translate(0 5.846)" fill="#fff" />
                                    <circle id="Ellipse_43" data-name="Ellipse 43" cx="3.797" cy="3.797" r="3.797" transform="translate(0)" fill="#fff" />
                                </svg>
                            </a>
                            <a href="https://x.com/StrandLife" target="_blank" rel="noopener noreferrer"  className="aspect-square w-[12vw] md:w-[4.25vw] rounded-full bg-[#272727] grid place-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-[3.5vw] md:h-[1.55vw]" viewBox="0 0 35.406 32.004">
                                    <path id="Path_460" data-name="Path 460" d="M111.949,100h5.429l-11.861,13.557L119.471,132H108.545l-8.557-11.188L90.2,132H84.764L97.45,117.5,84.065,100h11.2L103,110.227Zm-1.905,28.754h3.008L93.633,103.079H90.4Z" transform="translate(-84.065 -100)" fill="#fff" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-[#171717] py-[15vw] md:py-[5.25vw] p-[5.25vw] md:w-[40%]">
                        <h2 className="text-[10vw] md:text-[3.5vw] text-[#ffffff] leading-[1] md:leading-[.92] font-[350] relative z-[0] pt-[1vw]">Let’s start<br/>A Conversation <span className="h-[2.5px] w-[10vw] md:w-[4vw] md:mb-[.9vw] mb-[2vw] bg-[#F14234] inline-block"></span></h2>
                        <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={() => setOpenSnackbar(false)}
                >
                    {successMessage ? (
                        <Alert severity="success">{successMessage}</Alert>
                    ) : (
                        <Alert severity="error">{errorMessage}</Alert>
                    )}
                </Snackbar>
    
    
                        <div className="mt-[6vw] md:mt-[3vw]">
                            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-[8vw] md:gap-[3.5vw]">
                                <div className="relative">
                                    <div className="border-b border-white">
                                        <input required type="text"  {...register("name", { required: "Name is required" })}  placeholder="Name" className="text-[4vw] pb-[.35vw] md:text-[1.235vw] placeholder:text-[3.5vw] md:placeholder:text-[1.235vw] bg-transparent text-white font-[350] focus:outline-none focus:ring-0 w-full"/>
                                        </div>
                                        {errors.name && (
                                        <span style={{ color: 'red' }}>{errors.name.message}</span>)}
                                    
                                </div>
                                <div className="relative">
                                    <div className="border-b border-white">
                                    <input
                                   required
                                    type="tel"
                                    placeholder="Phone Number"
                                    {...register("phone", {
                                        required: "Phone Number is required",
                                        pattern: {
                                            value: /^[0-9]+$/, 
                                            message: "Please enter a valid phone number "
                                        }
                                    })}
                                    className="text-[4vw] pb-[.35vw] md:text-[1.235vw] placeholder:text-[3.5vw] md:placeholder:text-[1.235vw] bg-transparent text-white font-[350] focus:outline-none focus:ring-0 w-full"
                                />
                            </div>
                            {errors.phone && (
                                <span style={{ color: 'red' }}>{errors.phone.message}</span>
                            )}
                                                            
                                    </div>
                                <div className="relative">
                                    <div className="border-b border-white">
                                    <input required type="email"  {...register("email", { 
                                        required: "Email is required", 
                                        pattern: {
                                            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                            message: "Enter a valid email"} })}
                                        placeholder="Email ID" className="text-[4vw] pb-[.35vw] md:text-[1.235vw] placeholder:text-[3.5vw] md:placeholder:text-[1.235vw] bg-transparent text-white font-[350] focus:outline-none focus:ring-0 w-full"/>
                                         </div>
                                        {errors.email && (
                                        <span style={{ color: 'red' }}>
                                        {errors.email.message}
                                        </span>
                                    )}
                                </div>
                                <div className="relative">
                                    <div className="border-b border-white">
                                        <input required type="text" {...register("customer_inquiries_message", { required: "Message is required" })}  placeholder="Type your message here" className="text-[4vw] pb-[.35vw] md:text-[1.235vw] placeholder:text-[3.5vw] md:placeholder:text-[1.235vw] bg-transparent text-white font-[350] focus:outline-none focus:ring-0 w-full"/>
                                    </div>
                                    {errors.customer_inquiries_message&& (
                                        <span style={{ color: 'red' }}>
                                        {errors.customer_inquiries_message.message}
                                        </span>
                                    )}   
                                </div>
    
                     <div className="relative mt-[1vw]">
                            <button type="submit" disabled={disableSubmit}>
                                <div className={`flex items-center ${disableSubmit ? 'opacity-50' : ''}`}>
                                    <div className="flex items-center rounded-full border-[1.5px] border-[#FFFFFF] text-[#191818] h-[7vw] md:h-[2.65vw] bg-[#FFFFFF] font-og text-[3.05vw] md:text-[1.05vw] leading-[1] px-[3vw] py-[1vw] md:px-[1.25vw] md:py-[.65vw]">
                                        <p>{disableSubmit ? 'Submitting...' : 'SUBMIT MESSAGE'}</p>
                                    </div>
                                    <div className="bg-[#FFFFFF] h-[1.5px] md:h-[2.5px] w-[7vw] md:w-[2.75vw]"></div>
                                    <div className="rounded-full aspect-square flex items-center justify-center bg-[#FFFFFF] h-[7vw] md:h-[2.65vw]">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-[#F14234] w-[3vw] md:w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                        <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                                        <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                                        <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                                        <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </form>
                        </div>
                        
                    </div>
    </div> 

    <div className="px-[5.25vw] py-[1.75vw] md:py-[1vw] flex flex-col md:flex-row gap-1 justify-between items-center bg-[#171717] text-white text-[3vw] md:text-[1vw] border-t border-white">
        <p>2024 Copyright Strand Life Sciences All Rights Reserved</p>
    </div>
</div>
</footer>
    );
};



export default Landing_Footer;
