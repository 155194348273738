import { Link } from "react-router-dom";
import NonCurationTables from "../components/NonCurationTables";

function PubliclyCuratedDatasets () {    

    
    const apiParams = {
        page_name: "public_dataset",
    };
    
    const handleLinkClick = (urls: any) => {
        urls = urls.split(', ');
        urls.forEach((url: any) => {
            window.open(url, '_blank');
        });
    };
    const config = [
        { Id: 'DiseaseCategory', label: 'Disease Category', render: (Datasets: any) => Datasets.DiseaseCategory, isVisible: true, width: 177.5 },
        { Id: 'Disease', label: 'Disease', render: (Datasets: any) => Datasets.Disease, isVisible: true, width: 208 },
        { Id: 'ReferencePublication', label: 'Reference Publication', render: (Datasets: any) => Datasets.ReferencePublication.length === 0 ? "null" : <Link style={{textDecoration:'none', color: '#0C73EB'}} to="#" onClick={() => handleLinkClick(Datasets.link1)}>{Datasets.ReferencePublication}</Link>, isVisible: true, width: 920 },
        { Id: 'ReferenceDataset', label: 'Reference Dataset', render: (Datasets: any) => <Link style={{textDecoration:'none', color: '#0C73EB'}} target="_blank" to={Datasets.link2}>{Datasets.ReferenceDataset}</Link>, isVisible: true, width: 118.5 },
        { Id: 'YearOfPublication', label: 'Year of Publication', render: (Datasets: any) => Datasets.YearOfPublication, isVisible: true, width: 143.3 },
        { Id: 'Organism', label: 'Organism', render: (Datasets: any) => Datasets.Organism, isVisible: true, width: 90.61 }
    ];
    return <>
        
    <NonCurationTables config={config} requestFor="public_dataset" breadcrumbs={["/","Home","/publicly-curated-datasets", "Summary of Datasets"]} width={1850} rowkey="ReferenceDataset" apiParams={apiParams} curating="null"/>

    </>
}

export default PubliclyCuratedDatasets;