import { createSlice } from "@reduxjs/toolkit";

interface DiseaseState {
    diseaseCategory: string;
    diseaseTypeList: string[];
    diseaseType: string | null;
    seq: string; 
    diseaseToOptions: Record<string, string[]>;
}

const initialState: DiseaseState = {
    diseaseCategory: "Inflammatory Bowel Disease",
    diseaseTypeList: [],
    diseaseType: null,
    seq: "Single Cell RNA Seq",
    diseaseToOptions: {},
};

const diseaseSlice = createSlice({
    name: "disease",
    initialState,
    reducers: {
        updateDiseaseCategory: (state, action) => {
            const newDiseaseCategory = action.payload;
        
            if (state.diseaseCategory !== newDiseaseCategory) {
                state.diseaseCategory = newDiseaseCategory;
        
                const validOptions = state.diseaseToOptions[state.diseaseCategory==="Inflammatory Bowel Disease"?newDiseaseCategory:state.diseaseType] || [];

                if (!validOptions.includes(state.seq)) {
                    state.seq = "Single Cell RNA Seq";
                }
            }
        },
        
        updateDiseaseType: (state, action) => {
            const newDiseaseType = action.payload;
        
             if (state.diseaseType !== newDiseaseType) {
                state.diseaseType = newDiseaseType;
        
                const validOptions = state.diseaseToOptions[newDiseaseType] || [];
                
                if (!validOptions.includes(state.seq)) {
                    state.seq = "Single Cell RNA Seq";
                }
            }
        },        
        updateDiseaseTypeList: (state, action) => {
            state.diseaseTypeList = action.payload;
        },
        updateSeq: (state, action) => {
            state.seq = action.payload; 
        },
        updateDiseaseToOptions: (state, action) => {
            state.diseaseToOptions = action.payload; 
          },
        resetDiseaseParams: (state) => {
            state.diseaseCategory = "Inflammatory Bowel Disease";
            state.diseaseTypeList = [];
            state.diseaseType = null;
            state.seq = "Single Cell RNA Seq";
            state.diseaseToOptions = {}; 
        }
    }
});

export const { updateDiseaseCategory, updateDiseaseType, updateDiseaseTypeList,updateSeq,updateDiseaseToOptions,resetDiseaseParams } = diseaseSlice.actions;
export const selectDiseaseCategory = (state: { disease: DiseaseState }) => state.disease.diseaseCategory;
export const selectDiseaseType = (state: { disease: DiseaseState }) => state.disease.diseaseType;
export const selectDiseaseTypeList = (state: { disease: DiseaseState }) => state.disease.diseaseTypeList;
export const selectSeq = (state: { disease: DiseaseState }) => state.disease.seq;
export const selectDiseaseToOptions = (state: { disease: DiseaseState }) => state.disease.diseaseToOptions;
export default diseaseSlice.reducer;