import { Link } from "react-router-dom";
import NonCurationTables from "../components/NonCurationTables";
import { updateDiseaseCategory,updateDiseaseType } from '../store/slices/diseaseCategorySlice';
import { selectDiseaseCategory, selectDiseaseType } from "../store/slices/diseaseCategorySlice";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";

function PubliclyCuratedDatasets () {
    
    const diseaseCategory = useSelector(selectDiseaseCategory);
    const diseaseType=useSelector(selectDiseaseType)
    
    const dispatch = useDispatch();
    
    useEffect(() => {
      dispatch(updateDiseaseCategory(null));
      dispatch(updateDiseaseType(null));
    }, [dispatch]);
    
    const handleLinkClick = (urls: any) => {
        urls = urls.split(', ');
        urls.forEach((url: any) => {
            window.open(url, '_blank');
        });
    };
    const config = [
        { Id: 'DiseaseCategory', label: 'Disease Category', render: (Datasets: any) => Datasets.DiseaseCategory, isVisible: true, width: 177.5 },
        { Id: 'Disease', label: 'Disease', render: (Datasets: any) => Datasets.Disease, isVisible: true, width: 208 },
        { Id: 'ReferencePublication', label: 'Reference Publication', render: (Datasets: any) => Datasets.ReferencePublication.length === 0 ? "null" : <Link style={{textDecoration:'none', color: '#0C73EB'}} to="#" onClick={() => handleLinkClick(Datasets.link1)}>{Datasets.ReferencePublication}</Link>, isVisible: true, width: 920 },
        { Id: 'ReferenceDataset', label: 'Reference Dataset', render: (Datasets: any) => <Link style={{textDecoration:'none', color: '#0C73EB'}} target="_blank" to={Datasets.link2}>{Datasets.ReferenceDataset}</Link>, isVisible: true, width: 118.5 },
        { Id: 'YearOfPublication', label: 'Year of Publication', render: (Datasets: any) => Datasets.YearOfPublication, isVisible: true, width: 143.3 },
        { Id: 'Organism', label: 'Organism', render: (Datasets: any) => Datasets.Organism, isVisible: true, width: 90.61 }
    ];
    return <NonCurationTables config={config} requestFor="public_dataset" breadcrumbs={["/publicly-curated-datasets", "Curated Datasets from Public Resources"]} width={1850} rowkey="ReferenceDataset"/>;
}

export default PubliclyCuratedDatasets;