import React, { useState, Fragment, useEffect } from "react";
import Button from '@mui/material/Button';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import { AiOutlineClose } from 'react-icons/ai'
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters, selectFilters, updateFilters } from "../store/slices/filtersSlice";
import { selectUser } from "../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import FilterList from "./FilterDropdown";
import { selectDiseaseCategory, selectDiseaseType } from "../store/slices/diseaseCategorySlice";
import { selectSearchKey, setSearchKey } from "../store/slices/searchSlice";
import { Checkbox } from "@mui/material";

interface Filter {
    Id: string;
    label: string;
    isVisible: boolean;
}

interface SelectedFilters {
    [key: string]: string[];
}

interface SideBarProps {
    setTotalRows: (rows: number) => void;
    columns: Filter[];
    handleChange: (label: string) => void;
    handleFilteredRows: (rows: any) => void;
    curation: string;
}

function SideBar({ setTotalRows, columns, handleChange, handleFilteredRows, curation }: SideBarProps) {
    const [expanded, setExpanded] = useState<boolean>(true);
    const columnIds = columns.map((column) => {
        return column.Id;
    });
    const [filters, setFilters] = useState<SelectedFilters>({});
    const [totalCounts, setTotalCounts] = useState<{ [key: string]: number }>({});
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const diseaseCategory = useSelector(selectDiseaseCategory);
    const searchKey = useSelector(selectSearchKey);
    const diseaseType = useSelector(selectDiseaseType);
    const fetchFilters = async () => {
        let query = `/api/filter?DiseaseCategory=${diseaseCategory}`;
        if (diseaseCategory !== "Inflammatory Bowel Disease") {
            query += `&DiseaseType=${diseaseType}`;
        }
        try {
            const response = await axiosConfig.get(query);
            setTotalCounts(response.data.data.total_count);
            setFilters(response.data.data);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    setFilters((await axiosConfig.get(query)).data.data);
                    setTotalCounts((await axiosConfig.get(query)).data.data.total_count);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    }
    useEffect(() => {
        fetchFilters();
        // eslint-disable-next-line
    }, [diseaseType]);
    const filtersConfig = [
        { Id: 'GEOAccession', label: 'GEO Accession', isVisible: columnIds.includes('GEOAccession') },
        { Id: 'Contributor', label: 'Contributor', isVisible: columnIds.includes('Contributor') },
        { Id: 'CellType', label: 'Cell Type', isVisible: columnIds.includes('CellType') },
        { Id: 'CellLine', label: 'Cell Line', isVisible: columnIds.includes('CellLine') },
        { Id: 'Disease', label: 'Disease', isVisible: columnIds.includes('Disease') },
        { Id: 'Organism', label: 'Organism', isVisible: columnIds.includes('Organism') },
        { Id: 'PublicationYear', label: 'Publication Year', isVisible: columnIds.includes('PublicationYear') },
        { Id: 'LastUpdateYear', label: 'Last Update Year', isVisible: columnIds.includes('LastUpdateYear') },
        { Id: 'TechnologyType', label: 'Technology Type', isVisible: columnIds.includes('TechnologyType') },
        { Id: 'Tissue', label: 'Tissue', isVisible: columnIds.includes('Tissue') },
        { Id: 'Age', label: 'Age', isVisible: columnIds.includes('Age') },
        { Id: 'Gender', label: 'Gender', isVisible: columnIds.includes('Gender') },
        { Id: 'InstrumentModel', label: 'Sequencing Platform', isVisible: columnIds.includes('InstrumentModel') },
        { Id: 'CellSortingTechnique', label: 'Cell Sorting Technique', isVisible: columnIds.includes('CellSortingTechnique') },
        { Id: 'LibraryPreparationPlatform', label: 'Library Preparation Platform', isVisible: columnIds.includes('LibraryPreparationPlatform') },
        { Id: 'LibraryProcessingProtocol', label: 'Library Processing Protocol', isVisible: columnIds.includes('LibraryProcessingProtocol') },
        { Id: 'Sequencer', label: 'Sequencer', isVisible: columnIds.includes('Sequencer') },
        { Id: 'SequenceDataAligner', label: 'Sequence Data Aligner', isVisible: columnIds.includes('SequenceDataAligner') },
        { Id: 'SequenceDataProcessor', label: 'Sequence Data Processor', isVisible: columnIds.includes('SequenceDataProcessor') },
        { Id: 'SampleAcquisitionMethod', label: 'Sample Acquisition Method', isVisible: columnIds.includes('SampleAcquisitionMethod') },
        { Id: 'DiseaseStatus', label: 'Disease Status', isVisible: columnIds.includes('DiseaseStatus') },
        { Id: 'ExtractedMolecule', label: 'Extracted Molecule', isVisible: columnIds.includes('ExtractedMolecule') },
        { Id: 'LibraryStrategy', label: 'Library Strategy', isVisible: columnIds.includes('LibraryStrategy') },
        { Id: 'LibrarySource', label: 'Library Source', isVisible: columnIds.includes('LibrarySource') },
        { Id: 'LibrarySelection', label: 'Library Selection', isVisible: columnIds.includes('LibrarySelection') },
        { Id: 'NumberOfCells', label: 'Number of Cells', isVisible: columnIds.includes('NumberOfCells') },
        { Id: 'Treatment', label: 'Treatment', isVisible: columnIds.includes('Treatment') }
    ];
    const [width, setWidth] = useState<number>(260);
    const handleClick = () => {
        setExpanded(!expanded);
        if(!expanded) {
            for (let i=0;i<=130;i++) {
                setTimeout(() => setWidth(i+130), i*3);
            }
        }else {
            for (let i=260;i>=40;i--) {
                setTimeout(() => setWidth(i), (260-i)*1.5);
            }
        }
    };

    const selectedFilters = useSelector(selectFilters);
    const updateSelectedFilters = (updatedFilters: SelectedFilters) => {
        dispatch(updateFilters(updatedFilters));
    };

    const clearFilters = async () => {
        handleFilteredRows(['loader']);
        dispatch(resetFilters());
        if (searchKey.length > 0) {
            dispatch(setSearchKey(''));
            return;
        }
        let query = (curation === 'sample') ? "/api/sample" : (curation === 'study') ? "/api/study" : "/api/experiment";
        query += `?page=1&per_page=15&DiseaseCategory=${diseaseCategory}`;
        if (diseaseCategory !== "Inflammatory Bowel Disease") {
            query += `&DiseaseType=${diseaseType}`;
        }
        try {
            const response = await axiosConfig.get(query);
            setTotalRows(response.data.no_of_records);
            handleFilteredRows(response.data.data);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                } 
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    const refreshedResponse = await axiosConfig.get(query);
                    setTotalRows(refreshedResponse.data.no_of_records);
                    handleFilteredRows(refreshedResponse.data.data);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };

    const renderedFilterColumns = filtersConfig.map((filter) => {
        if ((Object.values(filters).some((value) => value.length > 0))) {
            return (
                <div key={filter.Id} style={{ marginBottom: '4.5px', display: 'flex' }}>
                    <FilterList filters={(filters as Record<string,string[]>)[filter.Id]} filter={filter} totalCounts={totalCounts} selectedFilters={selectedFilters} updateSelectedFilters={updateSelectedFilters} clearFilters={clearFilters} />
                </div>
            );
        }
        return null;
    });
    const checkboxStyles: React.CSSProperties = { marginRight: '2px', cursor: 'pointer', transform: "scale(0.85)", padding: 0 };
    const buttonStyles: React.CSSProperties = { textTransform: 'none', color: 'black', marginRight: '40px', display: 'inline-block', textAlign: 'left', padding: 0, width: 'auto' };
    const handleCheckboxChange = (label: string) => {
        handleChange(label);
    };
    const renderedVisibilityColumns = columns.map((column) => {
        return (
            <div key={column.Id} style={{ marginBottom: '4.5px', display: 'flex' }}>
                <Checkbox size="small" style={{...checkboxStyles, color: column.isVisible ? "#0C73EB" : "gray"}} checked={column.isVisible} onChange={() => handleCheckboxChange(column.label)}/>
                <Button id="tablecolumn" style={buttonStyles}>{column.label}</Button>
            </div>
        );
    });

    const righticonStyles: React.CSSProperties = { marginLeft: '1px', marginRight: '1px', cursor: 'pointer' };
    const lefticonStyles: React.CSSProperties = { cursor: 'pointer' };
    const filerlabelStyles: React.CSSProperties = { fontSize: '12pt', marginLeft: '3px', cursor: 'pointer'};

    const removeFilter = (key: string, value: string) => {
        const updatedFilters = { ...selectedFilters, [key]: selectedFilters[key].filter((item) => item !== value) };
        dispatch(updateFilters(updatedFilters));
        if (Object.values(selectedFilters).reduce((sum, filterArray) => sum + filterArray.length, 0) === 1) {
            clearFilters();
        }
    };

    const [activeTab, setActiveTab] = useState<string>('Filters');
    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };
    const filterLabel = (
        <div style={{ paddingTop: '15px', paddingBottom: '4px', position: 'sticky', zIndex: '1', top: '0', backgroundColor: 'white' }}>
            {expanded && <Fragment>
                <span className="navbar" onClick={() => handleTabClick('Filters')} style={{...filerlabelStyles, marginRight: '30px', position: 'relative'}}>
                    {activeTab === "Filters" ? <b style={{ color: '#0C73EB' }}>Filters</b> : <b style={{color:'gray'}}>Filters</b>}
                    {activeTab === 'Filters' && (<div style={{ position: 'absolute', bottom: '-4px', left: '0', width: '100%', height: '2px', backgroundColor: '#0C73EB' }}></div>)}
                </span>
                <span className="navbar" onClick={() => handleTabClick('Display')} style={{...filerlabelStyles, position: 'relative'}}>
                    {activeTab === "Display" ? <b style={{ color: '#0C73EB' }}>Display</b> : <b style={{color:'gray'}}>Display</b>}
                    {activeTab === 'Display' && (<div style={{ position: 'absolute', bottom: '-4px', left: '0', width: '100%', height: '2px', backgroundColor: '#0C73EB' }}></div>)}
                </span>
            </Fragment>}
            <span onClick={handleClick} style={{ marginLeft: '5px', float: 'right', marginRight: '8px' }}>
                {expanded ? <GoChevronLeft size={22} style={lefticonStyles} /> : <GoChevronRight style={righticonStyles} onClick={handleClick} size={26} />}
            </span>
        </div>
    );

    const renderedSelectedFilters = Object.keys(selectedFilters).map((key) => {
        if (selectedFilters[key].length > 0) {
            const renderedFilters = selectedFilters[key].map((value) => {
                return <Button onClick={() => removeFilter(key, value)} size="small" key={value} variant="contained" style={{ margin: '5px', whiteSpace: 'normal', backgroundColor: '#f1f3f6', color: 'black', textTransform: 'none' }}><AiOutlineClose size={10} style={{ marginRight: '10px' }} /><span className="filterbutton">{value}</span></Button>;
            });
            return (
                <Fragment key={key}>
                    <div style={{ marginLeft: '5px', marginTop: '2px' }}>{filtersConfig.find((filter) => filter.Id === key)?.label}</div>
                    <div style={{ marginBottom: '5px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {renderedFilters}
                    </div>
                </Fragment>
            );
        }
        return null;
    });

    const applyFilters = async () => {
        handleFilteredRows(['loader']);
        if (searchKey.length > 0) {
            dispatch(setSearchKey(''));
            return;
        }
        let query=`?page=1&per_page=15&DiseaseCategory=${diseaseCategory}&`;
        Object.keys(selectedFilters).forEach(filter => {
            selectedFilters[filter].forEach(value => {
                query += `${filter}=${encodeURIComponent(value)}&`;
            });
        });
        query = query.slice(0,-1);
        if (diseaseCategory !== "Inflammatory Bowel Disease") {
            query += `&DiseaseType=${diseaseType}`;
        }
        query = (curation === 'sample') ? `/api/sample${query}` : (curation === 'study') ? `/api/study${query}` : `/api/experiment${query}` ;
        try {
            const response = await axiosConfig.get(query);
            setTotalRows(response.data.no_of_records);
            handleFilteredRows(response.data.data);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked"){
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    const refreshedResponse = await axiosConfig.get(query);
                    setTotalRows(refreshedResponse.data.no_of_records);
                    handleFilteredRows(refreshedResponse.data.data);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    }

    const renderedContent = () => {
        if (activeTab === "Filters"){
            return renderedFilterColumns;
        }else if (activeTab === "Display"){
            return renderedVisibilityColumns;
        }
    };

    return (
        <div style={{ display: 'flex', width: `${width}px`, flexDirection: 'column' }}>
            {filterLabel}
            {expanded && activeTab === "Filters" && (Object.values(selectedFilters).some((value) => value.length > 0)) && 
                <span style={{ paddingBottom: '8px', position: 'sticky', zIndex: '1', top: '45px', backgroundColor: 'white' }}>
                    <Button size="medium" variant="contained" style={{ backgroundColor: '#0C73EB', textTransform: 'none', marginRight: '10px' }} onClick={applyFilters}>
                        <b>Apply Filters</b>
                    </Button>
                    <Button size="medium" variant="contained" style={{ backgroundColor: '#F1F3F6', textTransform: 'none', color: 'black' }} onClick={clearFilters}>
                        <b>Clear Filters</b>
                    </Button>
                </span>}
            {expanded && activeTab === "Filters" && renderedSelectedFilters}
            {expanded && renderedContent()}
        </div>
    );
}

export default SideBar;