import React, { useState, useEffect } from "react";
import { useDispatch} from "react-redux";
import { updateDiseaseCategory,updateDiseaseType} from "../store/slices/diseaseCategorySlice";
import { resetFilters } from "../store/slices/filtersSlice";
import { Link } from "react-router-dom";
import { axiosConfig } from "../axiosConfig";

const DiseaseDropdown: React.FC = () => {
  const dispatch = useDispatch();

 
  const [diseasesWithoutSubtypes, setDiseasesWithoutSubtypes] = useState<string[]>([]);
  const [diseasesWithSubtypes, setDiseasesWithSubtypes] = useState<string[]>([]);
  const [diseaseSubtypeToCategory, setDiseaseSubtypeToCategory] = useState<Record<string, string>>({});
  
  interface DiseaseCategoryData {
    [key: string]: string[];
  }

  const fetchDiseaseTypes = async () => {
    const maxRetries = 3; 
    const retryDelay = 1000; 
    const desiredOrder = ["Alzheimer's Disease", "Parkinson's Disease", "Frontotemporal Dementia"];
    
    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        const response = await axiosConfig.get(`/api/disease_category`);
        if (response.status === 200) {
          const data: DiseaseCategoryData = response.data.data;
  
          const withoutSubtypes: string[] = [];
          const withSubtypes: string[] = [];
          const subtypeToCategory: Record<string, string> = {};
          
        Object.entries(data).forEach(([category,subtypes])=>{
          if(subtypes.length===0)
            withoutSubtypes.push(category);
          else
          {
            const subTypesSorted=subtypes.sort((a,b)=>{
              const diseaseA=desiredOrder.indexOf(a);
              const diseaseB=desiredOrder.indexOf(b);
              if(diseaseA===-1&&diseaseB===-1)
                 return a.localeCompare(b)
              if(diseaseA===-1)
                 return 1;
              if(diseaseB===-1)
                return -1;

              return diseaseA-diseaseB;
            });

            subTypesSorted.forEach((subtype)=>{
              withSubtypes.push(subtype);
              subtypeToCategory[subtype]=category;

            });
          }

        });
  
          setDiseasesWithoutSubtypes(withoutSubtypes);
          setDiseasesWithSubtypes(withSubtypes);
          setDiseaseSubtypeToCategory(subtypeToCategory);
          return;
        }
      } catch (error) {
        console.error(`Attempt ${attempt + 1} failed:`, error);
      }
      await new Promise((resolve) => setTimeout(resolve, retryDelay * (attempt + 1)));
    }
  
    console.error("Failed to fetch disease categories after maximum retries.");
  };

    useEffect(() => {
    fetchDiseaseTypes();
  }, []);

  const handleDiseaseCategoryChange = (selectedDiseaseCategory: string) => {
    dispatch(resetFilters());
    dispatch(updateDiseaseCategory(selectedDiseaseCategory));
  };

  const handleDiseaseTypeChange = (selectedDiseaseType: string) => {
    dispatch(resetFilters());
    dispatch(updateDiseaseType(selectedDiseaseType));
  };

  return (
    <>
      {/* Mapping over diseases without subtypes */}
      {diseasesWithoutSubtypes.map((disease, index) => (
        <div key={index} className="group/first relative">
          <div className="px-[1vw] flex items-center cursor-pointer justify-between py-[.5vw] w-full duration-300 hover:bg-gray-50">
            <p>{disease}</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[90deg] text-[#F14234]" viewBox="0 0 24 24">
              <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
            </svg>
          </div>

          <div className="left-full w-max whitespace-nowrap group-hover/first:opacity-100 group-hover/first:pointer-events-auto absolute top-0 flex flex-col duration-300 bg-white shadow opacity-0 pointer-events-none">
            <div className="group/second relative">
              <div className="px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50 cursor-pointer flex justify-between items-center">
                <p>Single Cell RNA Seq</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[90deg] text-[#F14234]" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
                </svg>
              </div>

              <div className="left-full w-max whitespace-nowrap group-hover/second:opacity-100 group-hover/second:pointer-events-auto absolute top-0 flex flex-col duration-300 bg-white shadow opacity-0 pointer-events-none">
                <Link
                  to="/study-table"
                  className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                  onClick={() => handleDiseaseCategoryChange(disease)}
                >
                  Study Level Curation
                </Link>
                <Link
                  to="/experiment-table"
                  className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                  onClick={() => handleDiseaseCategoryChange(disease)}
                >
                  Experiment Level Curation
                </Link>
                <Link
                  to="/sample-table"
                  className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                  onClick={() => handleDiseaseCategoryChange(disease)}
                >
                  Sample Level Curation
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
       {/* Mapping over diseases with subtypes */}
       {diseasesWithSubtypes.map((disease, index) => (
        <div key={index} className="group/first relative">
          <div className="px-[1vw] flex items-center cursor-pointer justify-between py-[.5vw] w-full duration-300 hover:bg-gray-50">
            <p>{disease}</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[90deg] text-[#F14234]" viewBox="0 0 24 24">
              <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
            </svg>
          </div>

          <div className="left-full w-max whitespace-nowrap group-hover/first:opacity-100 group-hover/first:pointer-events-auto absolute top-0 flex flex-col duration-300 bg-white shadow opacity-0 pointer-events-none">
            <div className="group/second relative">
              <div className="px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50 cursor-pointer flex justify-between items-center">
                <p>Single Cell RNA Seq</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[90deg] text-[#F14234]" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
                </svg>
              </div>

              <div className="left-full w-max whitespace-nowrap group-hover/second:opacity-100 group-hover/second:pointer-events-auto absolute top-0 flex flex-col duration-300 bg-white shadow opacity-0 pointer-events-none">
                <Link
                  to="/study-table"
                  className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                  
                  onClick={() => {
                    const category = diseaseSubtypeToCategory[disease];handleDiseaseCategoryChange(category);handleDiseaseTypeChange(disease);}}
                >
                  Study Level Curation
                </Link>
                <Link
                  to="/experiment-table"
                  className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                  onClick={() => {
                    const category = diseaseSubtypeToCategory[disease];handleDiseaseCategoryChange(category);handleDiseaseTypeChange(disease);}}
 
                >
                  Experiment Level Curation
                </Link>
                <Link
                  to="/sample-table"
                  className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                  onClick={() => {
                    const category = diseaseSubtypeToCategory[disease];handleDiseaseCategoryChange(category);handleDiseaseTypeChange(disease);}}
 
                >
                  Sample Level Curation
                </Link>
              </div>
            </div>
          </div>
        </div>
))}


    </>
  );
};

export default DiseaseDropdown;
