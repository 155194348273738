import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import logo from "../companylogo.png"
import { Alert} from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { logout, selectUser } from "../store/slices/userSlice"
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig"
import { hashSync } from "bcrypt-ts"
import { IoWarning } from "react-icons/io5";
import { resetFilters } from "../store/slices/filtersSlice"
import NavigationAppBar from "../components/NavigationAppBar"
import BreadcrumbNavigation from "../components/BreadcrumbNavigation"

type FormValues = {
    currentPassword: string
    newPassword: string
    confirmPassword: string
}

function ChangePassword() {
    const form = useForm<FormValues>({
        defaultValues: {
            currentPassword: '', newPassword: '', confirmPassword: '',
        }
    });
    const { register, handleSubmit, reset, formState, setError } = form;
    const { errors } = formState;
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState<null | number>(null);
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const dispatch = useDispatch();
    const [disableSubmit, setDisableSubmit] = useState(false);
    useEffect(() => {
        if (!loggedUser.isAuthenticated && !loggedUser.email) {
            navigate('/login');
        }
    // eslint-disable-next-line
    }, []);
    const onSubmit = async (data: FormValues) => {
        if (data.newPassword !== data.confirmPassword) {
            setFeedback(1);
            setError("newPassword", { type: 'required', message: '' });
            setError("confirmPassword", { type: 'required', message: '' });
        } else {
            setFeedback(null);
            try {
                setDisableSubmit(true);
                const currentPasswordHash = hashSync(data.currentPassword, process.env.REACT_APP_API_SALT);
                const newPasswordHash = hashSync(data.newPassword, process.env.REACT_APP_API_SALT);
                const confirmPasswordHash = hashSync(data.confirmPassword, process.env.REACT_APP_API_SALT);
                let response: any;
                if (!loggedUser.isAuthenticated && loggedUser.email) {
                    response = await axiosConfig.put('/api/one_time_password', {
                        "email": loggedUser.email,
                        "user_password": currentPasswordHash,
                        "new_password": newPasswordHash,
                        "conf_password": confirmPasswordHash,
                    });
                }else if (loggedUser.isAuthenticated) {
                    response = await axiosConfig.put('/api/change_password', {
                        "user_password": currentPasswordHash,
                        "new_password": newPasswordHash,
                        "conf_password": confirmPasswordHash,
                    });
                }
                if (response.data.status === "SUCCESS") {
                    setFeedback(2);
                    dispatch(logout());
                    dispatch(resetFilters());
                    setAccessToken(null);
                    setDisableSubmit(false);
                    setTimeout(() => {
                        navigate('/login');
                        reset();
                    },0)
                }
            }catch(error: any) {
                if (error.response.data.data === "Incorrect old password") {
                    setDisableSubmit(false);
                    setError("currentPassword", { type: "required", message: "Current password incorrect" });
                }else {
                    try {
                        if (error.response.data.msg === "Token has been revoked") {
                            handleLogout(dispatch, navigate);
                        }
                        if (error.response.data.msg === "Token has expired") {
                            setDisableSubmit(false);
                            const response = await handleRefresh(loggedUser.refreshToken);
                            refreshAccessToken(dispatch, loggedUser, response);
                            document.getElementById('submit')?.click();
                        }
                    }catch(error: any) {
                        if (error.response.data.msg === "Token has expired") {
                            handleLogout(dispatch, navigate);
                        }
                    }
                }
            }
        }
    }

    return (
    <Fragment>
    {loggedUser.isAuthenticated && 
        <Fragment>
         <div className="nav-bar"><NavigationAppBar/>
         {/* <div className="breadcrumb-container" > */}
        <BreadcrumbNavigation breadcrumbs={[{path:'/',text:'Home'},{path:'/change-password',text:'Change Password'},]}/>
        </div>
    </Fragment>}
    <div className="modal-overlay-visible">
    <div className="modal-background"></div>
    <div
        className="modal-content-visible bg-white rounded-[3vw] md:rounded-[.85vw] px-[4vw] md:px-[2.5vw] py-[5vw] md:py-[2.5vw] w-[90vw] md:w-[37vw] relative"
        role="dialog"
        aria-labelledby="modal-title"
    >
        {/* Modal Content */}
        <div className="font-og">
            {/* Logo */}
            <div className="flex justify-center">
                <a href="/">
                    <img src={logo} className="w-[25vw] md:w-[10vw]" alt="Logo" />
                </a>
            </div>

            {/* Title */}
            <div className="mt-[6vw] md:mt-[3vw]">
                {loggedUser.isAuthenticated ? (
                    <p id="modal-title" className="text-[5vw] md:text-[2.5vw] leading-[1] text-center">
                        Change Password
                    </p>
                ) : (
                    <p id="modal-title" className="text-[5vw] md:text-[2.5vw] leading-[1] text-center">
                        <IoWarning size={35} style={{ marginBottom: "10px" }} color="#FF3333" /> Password Change Required
                    </p>
                )}
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-[3vw] md:gap-[1vw] mt-[7vw] md:mt-[4vw]">
                {/* Current Password */}
                <div className="relative">
                <div
                    style={{
                        border: "1px solid #D1D5DB", 
                        borderRadius: "0.375rem", 
                        padding: "2px", 
                        transition: "border-color 0.3s ease", 
                    }}
                      >                        <input
                            type="password"
                            placeholder="Current Password"
                            {...register("currentPassword", {
                                required: "Enter current password",
                            })}
                            className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
                        />
                    </div>
                    {errors.currentPassword && (
                      <span style={{ color: 'red' }}>{errors.currentPassword.message}</span>
                    )}
                </div>
            {/* New Password */}
            <div className="relative">
                  <div
                    style={{
                        border: `1px solid ${errors.confirmPassword || feedback === 1 ? "red" : "#D1D5DB"}`, 
                        borderRadius: "0.375rem", 
                        padding: "2px", 
                        transition: "border-color 0.3s ease", 
                    }}
                      >
                    <input
                        type="password"
                        placeholder="New Password"
                        {...register("newPassword", {
                            required: "Enter new password",
                            validate: (value) =>
                                value.length >= 8 || "Password must be at least 8 characters long",
                        })}
                        className={`text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0 ${
                            feedback === 1 ? "border-red-500" : ""
                        }`}
                    />
                </div>
                {errors.newPassword && (
                   <span style={{ color: 'red' }}>{errors.newPassword.message}</span>
                )}
            </div>

            {/* Confirm Password */}
            <div className="relative">
                   <div
                    style={{
                        border: `1px solid ${errors.confirmPassword || feedback === 1 ? "red" : "#D1D5DB"}`,
                        borderRadius: "0.375rem", 
                        padding: "2px", 
                        transition: "border-color 0.3s ease", 
                    }}
                      >
                    <input
                        type="password"
                        placeholder="Confirm New Password"
                        {...register("confirmPassword", {
                            required: "Reenter new password",
                            validate: (value) =>
                                value.length >= 8 || "Password must be at least 8 characters long",
                        })}
                        className={`text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0 ${
                            feedback === 1 ? "border--500" : ""
                        }`}
                    />
                </div>
                {errors.confirmPassword && (
                   <span style={{ color: 'red' }}>{errors.confirmPassword.message}</span>
                )}
            </div>

                {/* Feedback */}
                {feedback && (
                    <Alert style={{ fontSize: "16px" }} severity={feedback === 1 ? "error" : "success"}>
                        {feedback === 1 ? "Passwords do not match" : "Password changed successfully"}
                    </Alert>
                )}

                {/* Submit Button */}
                <div className="relative mt-[2vw]">
                    <button
                        type="submit"
                        className="rounded-[2vw] md:rounded-[.5vw] overflow-hidden bg-black text-white w-full text-[4vw] md:text-[1.1vw] px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw]"
                        disabled={disableSubmit}
                    >
                        Update Password
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>
         </Fragment>

        
    );
}

export default ChangePassword;

