import LoginModal from "../components/Landing_Login"

const LoginForm: React.FC = () => {

    return (
      <LoginModal
              customClassNames={{
                overlay: 'modal-overlay-visible',
                content: 'modal-content-visible',
              }}
              closeButton={false}
              signupNav={false}/>
            )
  }

  export default LoginForm;