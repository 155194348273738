import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../store/slices/userSlice";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import NavigationAppBar from "./NavigationAppBar";
import BreadcrumbNavigation from "./BreadcrumbNavigation";
import DataTable from "./DataTable";
import { CurationRequestLoader } from "./ComponentLoader";

interface ColumnConfig {
    Id: string;
    label: string;
    render: (Datasets: any) => any;
    isVisible: boolean;
    width: number;
}

interface TableProps {
    config: ColumnConfig[];
    requestFor: string;
    breadcrumbs: string[];
    width: number;
    rowkey: string;
}

function NonCurationTables({ config, requestFor, breadcrumbs, width, rowkey }: TableProps) {
    const [rows, setRows] = useState([]);
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [totalRows, setTotalRows] = useState(0);
    const [requestsFetched, setRequestsFetched] = useState(false);
    
    const fetchRequests = async (page: number = 1, perPage: number = 15) => {
        try {
            const response = await axiosConfig.get(`/api/${requestFor}?page=${page}&per_page=${perPage}`);
            setTotalRows(response.data.no_of_records);
            setRows(response.data.data);
            setRequestsFetched(true);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    const refreshedResponse = await axiosConfig.get(`/api/${requestFor}?page=${page}&per_page=${perPage}`);
                    setTotalRows(refreshedResponse.data.no_of_records);
                    setRows(refreshedResponse.data.data);
                    setRequestsFetched(true);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };

    useEffect(() => {
        fetchRequests();
    // eslint-disable-next-line
    }, []);
    let dataTableProps = {
        fetchFunction: fetchRequests,
        totalRows: totalRows,
        table: rows,
        columns: config.filter((column) => column.isVisible),
        width: width,
        rowkey: rowkey,
        selectedRows: [],
        handleNavigation: () => {}, // Default no-op function for navigation
    };

    return (
        <Fragment>
            <div><NavigationAppBar/></div>
            <div className="table">
                <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between', borderBottom:'1px solid #ccc' }}>
                    <BreadcrumbNavigation breadcrumbs={[{path: '/', text: 'Home'}, {path: breadcrumbs[0], text: breadcrumbs[1]}]}/>
                </div>
                {requestsFetched ? (
                    <DataTable {...dataTableProps} /> // Pass the props to DataTable
                ) : (
                    <CurationRequestLoader />
                )}
            </div>
        </Fragment>
    );
}

export default NonCurationTables;