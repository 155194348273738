import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../store/slices/userSlice";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import NonCurationTables from "../components/NonCurationTables";
import { updateDiseaseCategory,updateDiseaseType } from '../store/slices/diseaseCategorySlice';
import { useEffect } from "react";

function MyCurationRequests () {
    
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(updateDiseaseCategory(null));
        dispatch(updateDiseaseType("curation_request"));
      }, [dispatch]);

    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const navigate = useNavigate();
    const downloadCurationFile = async (order_id: number, filename: string) => {
        try {
            const response = await axiosConfig.get('/api/curation_file_download', { responseType: 'blob' , params: { "order_id": order_id } });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }catch(error: any) {
            try {
                const errdata = JSON.parse(await new Blob([error.response.data]).text());
                if (errdata.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (errdata.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    document.getElementById(String(order_id))?.click();
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };
    const config = [
        { Id: 'order_id', label: 'Order ID', render: (Datasets: any) => Datasets.order_id, isVisible: true, width: 53 },
        { Id: 'disease_category', label: 'Disease Category', render: (Datasets: any) => Datasets.disease_category, isVisible: true, width: 113 },
        { Id: 'affiliation', label: 'Affiliation', render: (Datasets: any) => Datasets.affiliation, isVisible: true, width: 64 },
        { Id: 'customized_curation', label: 'Customized Curation', render: (Datasets: any) => Datasets.customized_curation, isVisible: true, width: 135 },
        { Id: 'curation_file_name', label: 'Customized Metadata Fields', render: (Datasets: any) => Datasets.customized_curation === "Yes" ? <span id={String(Datasets.order_id)} style={{ textDecoration: 'none', color: '#0C73EB', cursor: 'pointer' }} onClick={() => downloadCurationFile(Datasets.order_id, Datasets.curation_file_name)}>{Datasets.curation_file_name}</span> : "-", isVisible: true, width: 183.5 },
        { Id: 'source_of_dataset', label: 'Source Of Dataset', render: (Datasets: any) => Datasets.source_of_dataset, isVisible: true, width: 117 },
        { Id: 'enter_url', label: 'Accession URL', render: (Datasets: any) => Datasets.source_of_dataset === "Publicly Available Datasets" ? <Link style={{textDecoration:'none', color: '#0C73EB'}} target="_blank" to={Datasets.enter_url}>Link</Link> : "-", isVisible: true, width: 97 },
        { Id: 'curation_status', label: 'Curation Status', render: (Datasets: any) => Datasets.curation_status, isVisible: true, width: 100 },
        { Id: 'CurationDownload', label: 'Curation Download', render: (Datasets: any) => Datasets.curation_status === "PENDING" ? "-" : <Button size="medium" variant="outlined" style={{ backgroundColor: '#0C73EB', textTransform: 'none', height: '30px', padding: '15px' }}><Link style={{textDecoration:'none',color:'white'}} target="_blank" to={Datasets.CurationDownload}>Download</Link></Button>, isVisible: true, width: 122 }
    ];
    return <NonCurationTables config={config} requestFor="order_table_read" breadcrumbs={["/curation-requests", "My Curation Requests"]} width={0} rowkey="order_id"/>;
}

export default MyCurationRequests;