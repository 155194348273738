
import React, {useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import {  selectDiseaseType,updateDiseaseCategory, updateDiseaseType} from "../store/slices/diseaseCategorySlice";
import { useDispatch, useSelector } from 'react-redux';
import { resetFilters } from '../store/slices/filtersSlice';
import { axiosConfig } from "../axiosConfig";
import logo from "../companylogo.png";
import { handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from '../axiosConfig';
import { selectUser} from '../store/slices/userSlice'; 
import Box from '@mui/material/Box';
import { Avatar, Divider, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import DiseaseDropdown from "./DiseaseDropdown";

const Landing_Header = () => {
    
    const user = useSelector(selectUser);
    const dispatch=useDispatch();
    const diseaseType = useSelector(selectDiseaseType);
    const handleDiseaseCategoryChange = (selectedDiseaseCategory: string) => {
        dispatch(resetFilters());
        dispatch(updateDiseaseCategory(selectedDiseaseCategory));
    };
    const handleDiseaseTypeChange = (selectedDiseaseType: string) => {
       
        dispatch(updateDiseaseType(selectedDiseaseType));
    };

    const linkStyles: React.CSSProperties = { textDecoration: 'none', color: 'black', cursor: 'pointer' };

    function LogoutMenu() {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const loggedUser = useSelector(selectUser);
        setAccessToken(loggedUser.accessToken);
        const handleLogoutButton = async () => {
            try {
                const response = await axiosConfig.post('/api/logout');
                if (response.data.status === "SUCCESS") {
                    handleLogout(dispatch,navigate);
                }
            }catch(error: any) {
                try{
                    if (error.response.data.msg === "Token has been revoked") {
                        handleLogout(dispatch, navigate);
                    }
                    if (error.response.data.msg === "Token has expired") {
                        const response = await handleRefresh(loggedUser.refreshToken);
                        refreshAccessToken(dispatch, loggedUser, response);
                        if((await axiosConfig.post('/api/logout')).data.status === "SUCCESS") {
                            handleLogout(dispatch, navigate);
                        }
                    }
                }catch(error: any) {
                    if (error.response.data.msg === "Token has expired") {
                        handleLogout(dispatch,navigate);
                    }
                }
            }
        }

        return (
            <>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <IconButton onClick={handleClick} size="small" sx={{ ml: 2, mb: 0.5 }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
                        <Avatar sx={{width: 48, height: 48, fontSize: '30px', fontWeight: 'bold'}}>{String(loggedUser.username)[0].toUpperCase()}</Avatar>
                    </IconButton>
                </Box>
                <Menu anchorEl={anchorEl} id="logoutmenu" open={open} onClose={(handleClose)} onClick={handleClose} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <MenuItem style={{paddingRight: '50px'}} onClick={handleClose}>
                        <ListItemText primary={loggedUser.username} secondary={loggedUser.email}/>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={() => { navigate('/user-profile'); }}>
                        <Link key="profile" to={'/user-profile'} style={linkStyles}>Profile</Link>
                    </MenuItem>
                    <MenuItem onClick={handleLogoutButton}>
                        <Link key="Logout" to={'/login'} style={linkStyles}>Logout</Link>
                    </MenuItem>
                </Menu>
            </>
        );
    }
       
    return(
        <header className="px-[5vw] md:px-[5.25vw] md:pb-[.5vw] py-[2.5vw] md:pt-[.8vw] border-b-[1.5px] border-[#191919] relative z-[1]">
        <div className="flex items-center justify-between">
            <a href="/" aria-label="Home Link">
                <img src={logo} className="w-[25vw] md:w-[10.8vw]" alt="logo" />
            </a>
            <div className="md:flex items-center gap-[3vw] hidden">
                <div className="flex gap-[2.25vw]">
                 
             
<div className="group relative">
    <button className="flex items-center gap-[.25vw] py-[1vw]">
        <p className="font-og text-[#242424] text-[1.05vw]">Datasets</p>
        <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[135deg] text-[#F14234]" viewBox="0 0 24 24">
            <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
        </svg>
    </button>

    <div className="top-full flex flex-col font-og text-[#242424] text-[1.05vw] absolute bg-white shadow w-max whitespace-nowrap duration-300 group-hover:opacity-100 opacity-0 group-hover:pointer-events-auto pointer-events-none">
        <Link to={"/publicly-curated-datasets"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50">
            Curated Datasets from Public Resources
        </Link>

            <DiseaseDropdown/>
                        
                <Link to={"/documentation"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50">Documentation</Link>
                        </div>
                    
                    </div>
                    
                  
                    <Link to="/metadata-visualization" className="flex items-center gap-[.25vw] py-[1vw]">
                        <p className="font-og text-[#242424] text-[1.05vw]">Metadata Visualization</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[135deg] text-[#F14234]" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
                        </svg>
                    </Link>
                    <div className="group relative">
                        <button className="flex items-center gap-[.25vw] py-[1vw]">
                            <p className="font-og text-[#242424] text-[1.05vw]">Curation Request</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[135deg] text-[#F14234]" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
                            </svg>
                        </button>
                        <div className="top-full flex flex-col font-og text-[#242424] text-[1.05vw] absolute bg-white shadow w-max whitespace-nowrap duration-300 group-hover:opacity-100 opacity-0 group-hover:pointer-events-auto pointer-events-none">
                            <Link to={"/create-curation-request"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50">Create Curation Request</Link>
                            <Link to={"/curation-requests"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50">My Curation Requests</Link>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-[1vw]">
               {user.isAuthenticated ?
                   
               <></>: <button data-modal-target="loginModal" className="open-modal-button flex h-[2.65vw] items-center rounded-full border-[1.5px] border-[#F14234] text-[#F14234] font-og text-[1.05vw] leading-[1] px-[1vw] py-[.65vw]">
                   <p>LOGIN</p>
               </button>}
                    <button data-modal-target="bookModal" className="open-modal-button flex items-center">
                        <div className="about-strand flex items-center rounded-full border-[1.5px] border-[#191919] text-[#FFFFFF] h-[2.65vw] bg-[#191919] font-og text-[1.05vw] leading-[1] px-[1vw] py-[.65vw]">
                            <p>BOOK A DEMO</p>
                        </div>
                        <div className="bg-[#191919] h-[2px] w-[.5vw]"></div>
                        <div className="rounded-full aspect-square flex items-center justify-center bg-[#191919] h-[2.65vw]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-white w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                    <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                    <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                    <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                </g>
                            </svg>
                        </div>
                    </button>
                    {user.isAuthenticated ?
                <LogoutMenu/>:<></>}
                </div>
            </div>

            {/* <!-- Mobile hamburger --> */}
            <button id="openNavMenu" className="md:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-[5.5vw] text-[#F14234]" viewBox="0 0 24 24">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M4 7h3m13 0h-9m9 10h-3M4 17h9m-9-5h16" />
                </svg>
            </button>
        </div>

        <div id="overlay" className="bg-opacity-10 fixed inset-0 bg-black"></div>

        <div id="menuNav" className="fixed z-[999] inset-y-0 right-0 md:hidden  flex max-w-[90%] translate-x-full">
            <div className="w-screen max-w-md">
                <div className="flex flex-col h-[100dvh] py-5 bg-white border-l shadow-lg border-neutral-100/70 relative">
                    <div className="px-[7vw]">
                        <div className="flex items-center justify-between py-3">
                            <h2 className="text-base font-semibold leading-6 text-gray-900" id="slide-over-title">
                                <img src={logo} className="w-[30vw]" alt="Logo"/>
                            </h2>
                            <div className="flex items-center h-auto ml-3">
                                <button aria-label="Close Menu" id="closeNavMenu" className="aspect-square text-neutral-600 hover:bg-neutral-100 z-30 flex items-center justify-center rounded-full">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-[7vw] h-[7vw]">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 relative overflow-y-scroll mt-[10vw]">
                        <div className="relative h-full flex flex-col gap-[5vw] font-medium justify-between">
                            <div className="flex flex-col text-[5vw] text-blackColor px-[3vw]">
                                <div className="accordion relative border-b">
                                    <div className="px-[5vw] py-[5vw] flex items-center justify-between accordion-toggle">
                                        <span className="relative">Curation Request</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                            <path fill="none" stroke="#396bd0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7" />
                                        </svg>
                                    </div>

                                    <div className="accordion-content">
                                        <div className="dropdown flex flex-col pl-[7vw] pb-[4vw] gap-[2vw]">
                                            <a href="#" className="text-[3.75vw]">
                                                Create Curation Request
                                            </a>
                                            <a href="#" className="text-[3.75vw]">
                                                My Curation Requests
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion relative border-b">
                                    <div className="px-[5vw] py-[5vw] flex items-center justify-between accordion-toggle">
                                        <span className="relative">Datasets</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                            <path fill="none" stroke="#396bd0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7" />
                                        </svg>
                                    </div>

                                    <div className="accordion-content">
                                        <div className="dropdown flex flex-col pl-[7vw] pb-[4vw] gap-[2vw]">
                                            <a href="#" className="text-[3.75vw]">
                                                Curated Datasets from Public Resources
                                            </a>
                                            <div className="accordion relative">
                                                <div className="flex items-center justify-between pr-[7vw] accordion-toggle">
                                                    <p className="text-[3.75vw]">Study Level Curation</p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                        <path fill="none" stroke="#396bd0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7" />
                                                    </svg>
                                                </div>
                                                <div className="accordion-content">
                                                    <div className="pl-[3vw] flex flex-col gap-[2vw] py-[2vw]">
                                                        <a href="#" className="text-[3.75vw]">
                                                            Inflammatory Bowel Disease
                                                        </a>
                                                        <a href="#" className="text-[3.75vw]">
                                                            Neurodegenerative Disease
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion relative">
                                                <div className="flex items-center justify-between pr-[7vw] accordion-toggle">
                                                    <p className="text-[3.75vw]">Experiment Level Curation</p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                        <path fill="none" stroke="#396bd0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7" />
                                                    </svg>
                                                </div>
                                                <div className="accordion-content">
                                                    <div className="pl-[3vw] flex flex-col gap-[2vw] py-[2vw]">
                                                        <a href="#" className="text-[3.75vw]">
                                                            Inflammatory Bowel Disease
                                                        </a>
                                                        <a href="#" className="text-[3.75vw]">
                                                            Neurodegenerative Disease
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion relative">
                                                <div className="flex items-center justify-between pr-[7vw] accordion-toggle">
                                                    <p className="text-[3.75vw]">Sample Level Curation</p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                        <path fill="none" stroke="#396bd0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7" />
                                                    </svg>
                                                </div>
                                                <div className="accordion-content">
                                                    <div className="pl-[3vw] flex flex-col gap-[2vw] py-[2vw]">
                                                        <a href="#" className="text-[3.75vw]">
                                                            Inflammatory Bowel Disease
                                                        </a>
                                                        <a href="#" className="text-[3.75vw]">
                                                            Neurodegenerative Disease
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Link aria-label="Metadata Visualization" to="/metadata-visualization" className="border-b">
                                    <div className="px-[5vw] py-[5vw]">
                                        <span className="relative">Metadata Visualization</span>
                                    </div>
                                </Link>
                                <button data-modal-target="loginModal" aria-label="Login Button" className="open-modal-button text-left border-b">
                                    <div className="px-[5vw] py-[5vw]">
                                        <span className="relative">Login</span>
                                    </div>
                                </button>
                                <button data-modal-target="bookModal" aria-label="Book a Demo Button" className="open-modal-button text-left border-b ">
                                    <div className="px-[5vw] py-[5vw]">
                                        <span className="relative">Book a Demo</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </header>

    );
};



export default Landing_Header;