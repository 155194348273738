import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { axiosConfig } from "../axiosConfig";
import { loadCaptchaEnginge, validateCaptcha, LoadCanvasTemplateNoReload  } from "react-simple-captcha";
import { TbReload } from "react-icons/tb";
import { Link } from "react-router-dom";
            
type FormValues = {
    fullName: string;
    workEmailId: string;
    work: string;
    captcha: string;
  };
  
  type SignupFormProps = {
    customClassNames?: {
      overlay?: string; 
      content?: string; 
    };
    closeButton?: boolean; 
    loginNav?: boolean;
  };
  
  const SignupForm: React.FC<SignupFormProps> = ({
    customClassNames = {},
    closeButton = true,
    loginNav=true
  }) => {
    const form = useForm<FormValues>({
      defaultValues: {
        fullName: '',
        workEmailId: '',
        work: '',
        captcha: '',
      },
    });
  
    const { register, handleSubmit, reset, formState, setError } = form;
    const { errors } = formState;
    const navigate = useNavigate();
    const [disableSubmit, setDisableSubmit] = useState(false);
  
    useEffect(() => {
      loadCaptchaEnginge(6); 
    }, []);
  
    const resetform = () => {
      reset();
    };
  
    const onSubmit = async (data: FormValues) => {
      try {
        setDisableSubmit(true);
        const response = await axiosConfig.post('/api/signup', {
          name: data.fullName,
          work_email: data.workEmailId,
          academic_affiliation: data.work,
          strand_restriction: process.env.REACT_APP_STRAND_RESTRICTION,
        });
  
        if (response.data.status === 'SUCCESS') {
          setDisableSubmit(false);
          setTimeout(() => {
            navigate('/signup-success', { state: 'signup' } );
            reset();
          }, 0);
        }
      } catch (error: any) {
        if (error.response.data.data === 'Email already exists') {
          setDisableSubmit(false);
          setError('workEmailId', { type: 'required', message: 'Email already exists' });
        }
      }
    };
  
    const { overlay = '', content = '' } = customClassNames;
        return(
    <div id="signupModal" className={`modal-overlay ${overlay}`}>
      <div className="modal-background"></div>
      <div className={`modal-content bg-white rounded-[3vw] md:rounded-[.85vw] px-[4vw] md:px-[2.5vw] py-[5vw] md:py-[2.5vw] w-[90vw] md:w-[37vw] relative my-[20vw] md:my-[1vw] ${content}`}>
        {closeButton && (
          <button
            onClick={resetform}
            data-modal-close="signupModal"
            className="close-modal-button absolute top-[2vw] right-[2vw] md:top-[1vw] md:right-[1vw]"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
              <path
                fill="#000000"
                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
              />
            </svg>
          </button>
        )}
         <div className="font-og">
                <div className="">
                    <p className="text-[5vw] md:text-[2.5vw] leading-[1] text-center">Sign up to ScRNA data<br/>curation portal v1.0</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} noValidate className="flex flex-col gap-[3vw] md:gap-[1vw] mt-[7vw] md:mt-[4vw]">
          {/* Full Name */}
          <div className="relative">
            <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
              <input
                type="text"
                {...register('fullName', { required: 'Full Name is required' })}
                placeholder="Full Name"
                className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
              />
            </div>
            {errors.fullName && <span style={{ color: 'red' }}>{errors.fullName.message}</span>}
          </div>
    
    
          {/* Work Email */}
          <div className="relative">
            <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
              <input
                type="email"
                {...register("workEmailId", {
                    required: {
                        value: true,
                        message: "Enter work email",
                    },
                    pattern: {
                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: "Enter a valid email",
                    },
                    validate: (workEmailId) => {
                        let domain = workEmailId.split('@')[1].toLowerCase();
                        if ( domain === "gmail.com" || domain === "yahoo.com" || domain === "hotmail.com" || domain === "outlook.com") {
                            return "Enter a valid work email";
                        }
                        if (process.env.REACT_APP_STRAND_RESTRICTION === "Y" && domain !== "strandls.com") {
                            return "Only @strandls domain allowed";
                        }
                    },
                })}
                placeholder="Work Email ID"
                className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
              />
            </div>
            {errors.workEmailId && <span style={{ color: 'red' }}>{errors.workEmailId.message}</span>}
          </div>
    
          {/* Work / Academic Affiliation */}
          <div className="relative">
            <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
              <input
                type="text"
                {...register("work", {
                    required: {
                        value: true,
                        message: "Enter work/academic affiliation",
                    },
                    validate: (work) => {
                        return work.length >= 5 || "Academic Affiliation must be at least 5 characters long" ; 
                    },
                })}            placeholder="Work/Academic Affiliation"
                className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
              />
            </div>
            {errors.work && <span style={{ color: 'red' }}>{errors.work.message}</span>}
          </div>    
        
     <div style={{display:'flex'}}>
                                        <LoadCanvasTemplateNoReload/>
                                        <div style={{cursor:'pointer'}} onClick={() => loadCaptchaEnginge(6)}><TbReload/></div>
                                    </div>
            <div className="relative">
                        <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                            <input
                            maxLength={6} // Limit captcha to 6 characters
                            autoComplete="off"
                            {...register('captcha', {
                                required: 'Enter the captcha text',
                                validate: (userCaptcha) =>
                                validateCaptcha(userCaptcha, false) || 'Captcha does not match', // Custom CAPTCHA validation function
                            })}
                            placeholder="Enter Captcha"
                            className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
                            />
                        </div>
                        {errors.captcha && <span style={{ color: 'red' }}>{errors.captcha.message}</span>}
                        </div>
    
          {/* Submit Button */}
          <div className="relative mt-[5vw] md:mt-[2vw]">
            <button type="submit" className="rounded-[2vw] md:rounded-[.5vw] bg-black text-white w-full text-[4vw] md:text-[1.1vw] px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw]">
              <p>Sign Up</p>
            </button>
          </div>
        </form>
                <div className="mt-[5vw] md:mt-[2vw]">
                    <div className="flex justify-center text-[3.25vw] md:text-[1.1vw] gap-[.5vw]">
                        Already have an account?
                        {loginNav?
                        <button data-modal-target="loginModal" className=" open-modal-button border border-black text-[3vw] md:text-[.85vw] leading-[1] rounded-full px-[2vw] md:px-[.75vw] py-[.75vw] md:py-[.2vw]">
                            Log In
                        </button>:
                         <Link
                         to="/login"
                         className="open-modal-button text-[3vw] md:text-[.85vw] leading-[1] rounded-full px-[2vw] md:px-[.75vw] py-[.75vw] md:py-[.2vw]"
                         style={{ border: "1px solid black" }}
                       >
                         Log In
                       </Link>}
                    </div>
                </div>
            </div>
        </div>
    </div>
        );
    };


export default SignupForm;
