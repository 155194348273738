import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateDiseaseCategory,
  updateDiseaseType,
} from "../store/slices/diseaseCategorySlice";
import { resetFilters } from "../store/slices/filtersSlice";
import { updateSeq } from "../store/slices/diseaseCategorySlice";
import { Link } from "react-router-dom";

interface DiseaseDropdownProps {
  diseasesWithoutSubtypes: string[];
  diseasesWithSubtypes: string[];
  diseaseSubtypeToCategory: Record<string, string>;
  diseaseToOptions: Record<string, string[]>; 
}

const DiseaseDropdown: React.FC<DiseaseDropdownProps> = ({
  diseasesWithoutSubtypes,
  diseasesWithSubtypes,
  diseaseSubtypeToCategory,
  diseaseToOptions,
}) => {
  const dispatch = useDispatch();

  const [activeMainMenu, setActiveMainMenu] = useState<string | null>(null);
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);

  const handleDiseaseCategoryChange = (selectedDiseaseCategory: string) => {
    dispatch(resetFilters());
    dispatch(updateDiseaseCategory(selectedDiseaseCategory));
  };

  const handleDiseaseTypeChange = (selectedDiseaseType: string) => {
    const category = diseaseSubtypeToCategory[selectedDiseaseType];
    dispatch(resetFilters());
    dispatch(updateDiseaseCategory(category));
    dispatch(updateDiseaseType(selectedDiseaseType));
  };

  const handleSeqChange = (option: string) => {
    if (option === "Other Transcriptomics Seq") {
      dispatch(updateSeq("Other Transcriptomics Seq"));
    } else {
      dispatch(updateSeq(option));
    }
  };

  const toggleMainMenu = (disease: string) => {
    setActiveMainMenu(disease);
    setActiveSubMenu(null);
  };

  const toggleSubMenu = (subMenu: string) => {
    setActiveSubMenu(subMenu);
  };

  const closeMainMenu = () => {
    setActiveMainMenu(null);
    setActiveSubMenu(null);
  };

  return (
    <div>
      {/* Render All Diseases and their Submenus */}
      {Object.keys(diseaseToOptions).map((disease, index) => (
        <div
          key={index}
          className="relative"
          onMouseEnter={() => toggleMainMenu(disease)}
          onMouseLeave={closeMainMenu}
        >
          <div className="px-[1vw] flex items-center cursor-pointer justify-between py-[.5vw] w-full duration-300 hover:bg-gray-50">
            <p>{disease}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-[1.15vw] rotate-[90deg] text-[#F14234]"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z"
              />
            </svg>
          </div>

          {activeMainMenu === disease && (
            <div className="left-full w-max whitespace-nowrap absolute top-0 flex flex-col duration-300 bg-white shadow">
              {diseaseToOptions[disease]?.map((option, subIndex) => (
                <div
                  key={subIndex}
                  className="relative"
                  onMouseEnter={() => toggleSubMenu(option)}
                  onMouseLeave={() => toggleSubMenu("")}
                >
                  <div className="px-[1vw] gap-[.25vw] py-[.5vw] duration-300 hover:bg-gray-50 cursor-pointer flex justify-between items-center">
                    <p>
                    {option === "Single Cell RNA Seq"
                      ? "Single-Cell Sequencing"
                      : option === "Other Transcriptomics Seq"
                      ? "Other Transcriptomics"
                      : option}
                   </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[1.15vw] rotate-[90deg] text-[#F14234]"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z"
                      />
                    </svg>
                  </div>

                  {activeSubMenu === option && (
                    <div className="left-full w-max whitespace-nowrap absolute top-0 flex flex-col duration-300 bg-white shadow">
                      <Link
                        to="/study-table"
                        className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                        onClick={() => {
                          handleDiseaseCategoryChange(disease);
                          handleSeqChange(option);
                          if (diseasesWithSubtypes.includes(disease)) {
                            handleDiseaseTypeChange(disease);
                          }
                        }}
                      >
                        Study Level Curation
                      </Link>
                      <Link
                        to="/experiment-table"
                        className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                        onClick={() => {
                          handleDiseaseCategoryChange(disease);
                          handleSeqChange(option);
                          if (diseasesWithSubtypes.includes(disease)) {
                            handleDiseaseTypeChange(disease);
                          }
                        }}
                      >
                        Experiment Level Curation
                      </Link>
                      <Link
                        to="/sample-table"
                        className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                        onClick={() => {
                          handleDiseaseCategoryChange(disease);
                          handleSeqChange(option);
                          if (diseasesWithSubtypes.includes(disease)) {
                            handleDiseaseTypeChange(disease);
                          }
                        }}
                      >
                        Sample Level Curation
                      </Link>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DiseaseDropdown;

