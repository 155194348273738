import "../components/assets/css/input.css"
import "../components/assets/css/output.css"
import { selectUser} from '../store/slices/userSlice'; 
import Footer from '../components/Landing_Footer'
import Features from '../components/Landing_Features';
import Header from '../components/Landing_Header'
import SignUp from '../components/Landing_Signup'
import BookaDemo from '../components/BookaDemo'
import Login from '../components/Landing_Login'
import { useSelector } from "react-redux";
import { useEffect } from "react";

const LandingNew: React.FC = () => { 
 
    const user = useSelector(selectUser);
    useEffect(() => {
        const openNavMenu = document.getElementById('openNavMenu');
        const closeNavMenu = document.getElementById('closeNavMenu');
        const overlay = document.getElementById('overlay');
        const menuNav = document.getElementById('menuNav');
      
      
    function openMenu() {
        if (menuNav && overlay) {
          menuNav.style.transform = 'translateX(0)';
          overlay.style.opacity = '1';
          overlay.style.pointerEvents = 'auto';
        }
      }
    
      function closeMenu() {
        if (menuNav && overlay) {
          menuNav.style.transform = 'translateX(100%)';
          overlay.style.opacity = '0';
          overlay.style.pointerEvents = 'none';
        }
      }
    
      function init() {
        if (menuNav && overlay) {
          menuNav.style.transform = 'translateX(100%)';
          overlay.style.opacity = '0';
          overlay.style.pointerEvents = 'none';
        }
      }
    
      init();
      
        openNavMenu?.addEventListener('click', openMenu);
        closeNavMenu?.addEventListener('click', closeMenu);
        overlay?.addEventListener('click', closeMenu);
      
        // Modal functions
        const openModal = (modalId: string) => {
          document.querySelectorAll('.modal-overlay.show').forEach(openModal => {
            openModal.classList.remove('show');
          });
      
          const modal = document.getElementById(modalId);
          if (modal) {
            modal.classList.add('show');
          }
      
          // Reattach event listeners for buttons inside the newly opened modal
          attachModalEventListeners();
        };
      
        const closeModal = (modalId: string) => {
          const modal = document.getElementById(modalId);
          if (modal) {
            modal.classList.remove('show');
          }
        };
      
        const attachModalEventListeners = () => {
          document.querySelectorAll('.open-modal-button').forEach(button => {
            button.addEventListener('click', () => {
              const modalId = button.getAttribute('data-modal-target');
              openModal(modalId!);
            });
          });
      
          document.querySelectorAll('.close-modal-button').forEach(button => {
            button.addEventListener('click', () => {
              const modalId = button.getAttribute('data-modal-close');
              closeModal(modalId!);
            });
          });
      
          // Handle Escape key to close modals
          document.addEventListener('keydown', handleEscapeKey);
        };
      
        const handleEscapeKey = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            document.querySelectorAll('.modal-overlay.show').forEach(modal => {
              modal.classList.remove('show');
            });
          }
        };
      
        // Attach initial modal event listeners
        attachModalEventListeners();
      
        return () => {
          openNavMenu?.removeEventListener('click', openMenu);
          closeNavMenu?.removeEventListener('click', closeMenu);
          overlay?.removeEventListener('click', closeMenu);
          
          // Clean up modal event listeners
          document.querySelectorAll('.open-modal-button').forEach(button => {
            button.removeEventListener('click', () => {
              const modalId = button.getAttribute('data-modal-target');
              openModal(modalId!);
            });
          });
      
          document.querySelectorAll('.close-modal-button').forEach(button => {
            button.removeEventListener('click', () => {
              const modalId = button.getAttribute('data-modal-close');
              closeModal(modalId!);
            });
          });
      
          // Remove Escape key listener
          document.removeEventListener('keydown', handleEscapeKey);
        };
      }, [user.isAuthenticated]);

    return (
        <div className="custom-component">
            
            <Header/>       
            <Login/>
            <SignUp/>
            <BookaDemo/>

            <main className="about-strand relative z-[0]"> 
                <Features/>
            </main>

            <Footer/>
      
        </div>
)
}

export default LandingNew;