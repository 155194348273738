import { Paper, Stack, TextField, Tooltip } from "@mui/material";
import BreadcrumbNavigation from "../components/BreadcrumbNavigation";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, selectUser } from "../store/slices/userSlice";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import { Fragment, useEffect, useState } from "react";
import NavigationAppBar from "../components/NavigationAppBar";
import { CiEdit } from "react-icons/ci";
import { FaSave } from "react-icons/fa";

function UserProfile() {
    const navigate = useNavigate();
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const dispatch = useDispatch();
    const [editable, setEditable] = useState(false);
    const [profile, setProfile] = useState({ name: '', work_email: '', academic_affiliation: '', uid: '' });
    const fetchProfile = async () => {
        try {
            const response = await axiosConfig.get('/api/profile');
            setProfile(response.data.data);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    setProfile((await axiosConfig.get("/api/profile")).data.data);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };
    useEffect(() => {
        fetchProfile();
    // eslint-disable-next-line
    }, []);
    const handleSaveProfile = async () => {
        if (profile.name.length > 4 && profile.academic_affiliation.length > 4 ) {
            setEditable(false);
            try {
                const response = await axiosConfig.post('/api/profile_update', { "name": profile.name, "academic_affiliation": profile.academic_affiliation } );
                if (response.data.status === "SUCCESS"){
                    dispatch(login({"username": profile.name, "email": loggedUser.email, "isAuthenticated": true, "accessToken": loggedUser.accessToken, "refreshToken": loggedUser.refreshToken}));
                }
            }catch(error: any) {
                try {
                    if (error.response.data.msg === "Token has been revoked") {
                        handleLogout(dispatch, navigate);
                    }
                    if (error.response.data.msg === "Token has expired") {
                        const response = await handleRefresh(loggedUser.refreshToken);
                        refreshAccessToken(dispatch, loggedUser, response);
                        setProfile((await axiosConfig.get("/api/profile")).data.data);
                    }
                }catch(error: any) {
                    if (error.response.data.msg === "Token has expired") {
                        handleLogout(dispatch, navigate);
                    }
                }
            }
        }
    };
    return (
        <Fragment>
            <div><NavigationAppBar/></div>
            <BreadcrumbNavigation breadcrumbs={[{path:'/',text:'Home'},{path:'/user-profile',text:'User Profile'},]}/>
             <div className="table" style={{ overflow: 'auto', marginTop: '-7px', marginLeft: '-8px', marginRight: '-8px', marginBottom: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', minHeight: '85vh', borderRadius: '20px' }}>
                    <Paper elevation={12} style={{ borderRadius:'20px' }}>
                        <div style={{ textAlign: 'left', backgroundColor: 'white', padding: '20px', paddingBottom: '25px', border: '1px solid #ccc', borderRadius: '20px' }}>
                            <Stack direction="row">
                                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>My Profile</p>
                                {editable ? 
                                    <Tooltip title="Save Profile" placement="right-start"><span style={{marginTop: '4px', marginLeft: '215px'}}><FaSave onClick={handleSaveProfile} size={26} style={{cursor: 'pointer'}}/></span></Tooltip> : 
                                    <Tooltip title="Edit Profile" placement="right-start"><span style={{marginTop: '4px', marginLeft: '215px'}}><CiEdit onClick={() => setEditable(true)} size={26} style={{cursor: 'pointer'}}/></span></Tooltip>}
                            </Stack>
                            <hr style={{marginTop: '-1px', marginBottom: '22px'}}/>
                            <Stack spacing={2.25} width={360}>
                                <Stack spacing={0.75}>
                                    <label style={{ fontSize: '16px', marginLeft: '3px' }}>Full Name</label>
                                    <TextField autoComplete="off" disabled={!editable} onChange={(event) => setProfile({...profile, name: event.target.value})} size="small" value={profile.name} error={profile.name.length < 5} helperText={profile.name.length > 0 && profile.name.length < 5 && "Full Name must be at least 5 characters long"}/>
                                </Stack>
                                <Stack spacing={0.75}>
                                    <label style={{ fontSize: '16px', marginLeft: '3px' }}>Work Email ID</label>
                                    <TextField disabled size="small" value={profile.work_email}/>
                                </Stack>
                                <Stack spacing={0.75}>
                                    <label style={{ fontSize: '16px', marginLeft: '3px' }}>Work/Academic Affiliation</label>
                                    <TextField autoComplete="off" disabled={!editable} onChange={(event) => setProfile({...profile, academic_affiliation: event.target.value})} size="small" value={profile.academic_affiliation} error={profile.academic_affiliation.length < 5} helperText={profile.academic_affiliation.length >0  && profile.academic_affiliation.length < 5 && "Academic affiliation must be at least 5 characters long"}/>
                                </Stack>
                                <Stack spacing={0.75}>
                                    <label style={{ fontSize: '16px', marginLeft: '3px' }}>Customer ID</label>
                                    <TextField disabled size="small" value={profile.uid}/>
                                </Stack>
                            </Stack>
                            <p style={{ textAlign: 'center', fontSize: '18px', marginTop: '26px' }}> Change password? <span style={{ color: '#0C73EB', cursor: 'pointer' }} 
                            onMouseEnter={(e) => {(e.target as HTMLElement).style.textDecoration = 'underline'}}
                            onMouseLeave={(e) => {(e.target as HTMLElement).style.textDecoration = 'none'}} onClick={() => { navigate('/change-password'); }}>Reset Password</span></p>
                        </div>
                    </Paper>
                </div>
            </div>
        </Fragment>
    );
}

export default UserProfile;