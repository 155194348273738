import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectDiseaseCategory, selectDiseaseType } from "../store/slices/diseaseCategorySlice";
import CurationTable from "../components/CurationTable";

function SampleLevelCurationTable() {
    const [config, setConfig] = useState([]);
    const diseaseCategory = useSelector(selectDiseaseCategory);
    const diseaseType = useSelector(selectDiseaseType);
    let width: number = (diseaseCategory === "Inflammatory Bowel Disease") ? 8000 : (diseaseType === "Frontotemporal Dementia"||diseaseType === "Parkinson's Disease") ? 6600 : 8835;

    useEffect(() => {
        // eslint-disable-next-line
        setConfig([
            { Id: 'GeoAccessionId', label: 'Geo Accession Id', render: (Datasets: any) => Datasets.GeoAccessionId, isVisible: true, width: 111.83 },
            { Id: 'SampleAccessionId', label: 'Sample Accession Id', render: (Datasets: any) => Datasets.SampleAccessionId, isVisible: true, width: 155.64 },
            { Id: 'Organism', label: 'Organism', render: (Datasets: any) => Datasets.Organism, isVisible: true, width: 62.22 },
            { Id: 'Strain', label: 'Strain', render: (Datasets: any) => Datasets.Strain, isVisible: true, width: 100.2 },
            { Id: 'Gender', label: 'Gender', render: (Datasets: any) => Datasets.Gender, isVisible: true, width: 46.63 },
            { Id: 'Age', label: 'Age', render: (Datasets: any) => Datasets.Age, isVisible: true, width: 25.44 },
            { Id: 'AgeUnit', label: 'Age Unit', render: (Datasets: any) => Datasets.AgeUnit, isVisible: true, width: 55.17 },
            { Id: 'MinimumAge', label: 'Minimum Age', render: (Datasets: any) => Datasets.MinimumAge, isVisible: true, width: 89.72 },
            { Id: 'MaximumAge', label: 'Maximum Age', render: (Datasets: any) => Datasets.MaximumAge, isVisible: true, width: 92.8 },
            { Id: 'Tissue', label: 'Tissue', render: (Datasets: any) => Datasets.Tissue, isVisible: true, width: 113.08 },
            { Id: 'Disease', label: 'Disease', render: (Datasets: any) => Datasets.Disease, isVisible: true, width: 100.91 },
            { Id: 'CellType', label: 'Cell Type', render: (Datasets: any) => Datasets.CellType, isVisible: true, width: 900 },
            { Id: 'CellLine', label: 'Cell Line', render: (Datasets: any) => Datasets.CellLine, isVisible: true, width: 105.7 },
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'DiseaseState', label: 'Disease State', render: (Datasets: any) => Datasets.DiseaseState, isVisible: true, width: 88.44 }),
            { Id: 'DiseaseStatus', label: 'Disease Status', render: (Datasets: any) => Datasets.DiseaseStatus, isVisible: true, width: 96.05 },
            { Id: 'ExtractedMolecule', label: 'Extracted Molecule', render: (Datasets: any) => Datasets.ExtractedMolecule, isVisible: true, width: 123.69 },
            { Id: 'LibraryStrategy', label: 'Library Strategy', render: (Datasets: any) => Datasets.LibraryStrategy, isVisible: true, width: 102.8 },
            { Id: 'LibrarySource', label: 'Library Source', render: (Datasets: any) => Datasets.LibrarySource, isVisible: true, width: 95.7 },
            { Id: 'LibrarySelection', label: 'Library Selection', render: (Datasets: any) => Datasets.LibrarySelection, isVisible: true, width: 108.83 },
            { Id: 'InstrumentModel', label: 'Sequencing Platform', render: (Datasets: any) => Datasets.InstrumentModel, isVisible: true, width: 135.66 },
            { Id: 'Treatment', label: 'Treatment', render: (Datasets: any) => Datasets.Treatment, isVisible: true, width: 116.61 },
            { Id: 'TreatmentResponse',label: 'Treatment Response', render: (Datasets: any) => Datasets.TreatmentResponse, isVisible: true, width: 133.55 },
            { Id: 'Technologytype',label: 'Technology Type', render: (Datasets: any) => Datasets.Technologytype, isVisible: true, width: 109.06 },
            { Id: 'CancerStage', label: 'Cancer Stage', render: (Datasets: any) => Datasets.CancerStage, isVisible: true, width: 86.17 },
            { Id: 'CancerGrade', label: 'Cancer Grade', render: (Datasets: any) => Datasets.CancerGrade, isVisible: true, width: 87.17 },
            { Id: 'SamplingSite', label: 'Sampling Site', render: (Datasets: any) => Datasets.SamplingSite, isVisible: true, width: 89.42 },
            { Id: 'DevelopmentStage', label: 'Development Stage', render: (Datasets: any) => Datasets.DevelopmentStage, isVisible:true, width: 125.34 },
            { Id: 'DevelopmentStageUnit', label: 'Development Stage Unit', render: (Datasets: any) => Datasets.DevelopmentStageUnit, isVisible: true, width: 155.08 },
            { Id: 'Status', label: 'Status', render: (Datasets: any) => Datasets.Status, isVisible: true, width: 300 },
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'Individual', label: 'Individual', render: (Datasets: any) => Datasets.Individual, isVisible: true, width: 62.75 }),
            { Id: 'Title', label: 'Sample Title', render: (Datasets: any) => Datasets.Title, isVisible: true, width: 95.84 },
            { Id: 'SampleType', label: 'Sample Type', render: (Datasets: any) => Datasets.SampleType, isVisible: true, width: 82.7 },
            { Id: 'SourceName', label: 'Source Name', render: (Datasets: any) => Datasets.SourceName, isVisible: true, width: 171.42 },
            { Id: 'TreatmentProtocol', label: 'Treatment Protocol', render: (Datasets: any) => Datasets.TreatmentProtocol, isVisible: true, width: 500.63 },
            { Id: 'GrowthProtocol', label: 'Growth Protocol', render: (Datasets: any) => Datasets.GrowthProtocol, isVisible: true, width: 524.08 },
            { Id: 'ExtractionProtocol', label: 'Extraction Protocol', render: (Datasets: any) => Datasets.ExtractionProtocol, isVisible: true, width: 593.16 },
            { Id: 'DataProcessing', label: 'Data Processing', render: (Datasets: any) => Datasets.DataProcessing, isVisible: true, width: 608.02 },
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'SampleId', label: 'Sample Id', render: (Datasets: any) => Datasets.SampleId, isVisible: true, width: 64.2 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'SampleGroup', label: 'Sample Group', render: (Datasets: any) => Datasets.SampleGroup, isVisible: true, width: 90.72 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'PatientNumber', label: 'Patient Number', render: (Datasets: any) => Datasets.PatientNumber, isVisible: true, width: 101.03 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'AlphaeStatus', label: 'Alphae Status', render: (Datasets: any) => Datasets.AlphaeStatus, isVisible: true, width: 89.97 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'PatientId', label: 'Patient Id', render: (Datasets: any) => Datasets.PatientId, isVisible: true, width: 62 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'AnalysisPipeline', label: 'Analysis Pipeline', render: (Datasets: any) => Datasets.AnalysisPipeline, isVisible: true, width: 110.36 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'AnalysisPlatform', label: 'Analysis Platform', render: (Datasets: any) => Datasets.AnalysisPlatform, isVisible: true, width: 114.47 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'SubjectIdentifier', label: 'Subject Identifier', render: (Datasets: any) => Datasets.SubjectIdentifier, isVisible: true, width: 110.97 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'SubjectStatus', label: 'Subject Status', render: (Datasets: any) => Datasets.SubjectStatus, isVisible: true, width: 93.75 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'SampleInformation', label: 'Sample Information', render: (Datasets: any) => Datasets.SampleInformation, isVisible: true, width: 127.38 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'DonorId', label: 'Donor Id', render: (Datasets: any) => Datasets.DonorId, isVisible: true, width: 54.55 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'Condition', label: 'Condition', render: (Datasets: any) => Datasets.Condition, isVisible: true, width: 54.55 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'PouchitisDiagnosis', label: 'Pouchitis Diagnosis', render: (Datasets: any) => Datasets.PouchitisDiagnosis, isVisible: true, width: 54.55 }),
            (diseaseCategory === "Inflammatory Bowel Disease" && { Id: 'Stimulation', label: 'Stimulation', render: (Datasets: any) => Datasets.Stimulation, isVisible: true, width: 82.7 }),
            (diseaseCategory === "Inflammatory Bowel Disease" &&{ Id: 'Patient Origin', label: 'Patient Origin', render: (Datasets: any) => Datasets['PatientOrigin'], isVisible: true, width: 82.7 }),
            (diseaseCategory === "Inflammatory Bowel Disease" &&{ Id: 'Cell Markers', label: 'Cell Markers', render: (Datasets: any) => Datasets['CellMarkers'], isVisible: true, width: 82.7 }),
            (diseaseCategory === "Inflammatory Bowel Disease" &&{ Id: 'Patient Diagnosis', label: 'Patient Diagnosis', render: (Datasets: any) => Datasets['PatientDiagnosis'], isVisible: true, width: 82.7 }),
            (diseaseCategory === "Inflammatory Bowel Disease" &&{ Id: 'Patient', label: 'Patient', render: (Datasets: any) => Datasets.Patient, isVisible: true, width: 82.7 }),
            (diseaseCategory === "Inflammatory Bowel Disease" &&{ Id: 'Adt', label: 'Adt', render: (Datasets: any) => Datasets.Adt, isVisible: true, width: 82.7 }),
            (diseaseCategory === "Inflammatory Bowel Disease" &&{ Id: 'Library type', label: 'Library type', render: (Datasets: any) => Datasets.LibraryType, isVisible: true, width: 82.7 }),
            (diseaseCategory === "Inflammatory Bowel Disease" &&{ Id: 'Hto', label: 'Hto', render: (Datasets: any) => Datasets.Hto, isVisible: true, width: 82.7 }),
            (diseaseCategory === "Inflammatory Bowel Disease" &&{ Id: 'Antibodies/tags', label: 'Antibodies/tags', render: (Datasets: any) => Datasets['Antibodies/tags'], isVisible: true, width: 82.7 }),
            (diseaseType === "Frontotemporal Dementia" && { Id: 'Batch', label: 'Batch', render: (Datasets: any) => Datasets.Batch, isVisible: true, width: 100 }),
            (diseaseCategory === "Neurodegenerative Disease" && { Id: 'Genotype', label: 'Genotype', render: (Datasets: any) => Datasets.Genotype, isVisible: true, width: 100 }),
           (diseaseType === "Frontotemporal Dementia" && { Id: 'GfpStatus', label: 'Gfp Status', render: (Datasets: any) => Datasets.GfpStatus, isVisible: true, width: 100 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'LibraryType', label: 'Library Type', render: (Datasets: any) => Datasets.LibraryType, isVisible: true, width: 82.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'BrainRegion', label: 'Brain Region', render: (Datasets: any) => Datasets.BrainRegion, isVisible: true, width: 82.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Diagnosis', label: 'Diagnosis', render: (Datasets: any) => Datasets.Diagnosis, isVisible: true, width: 82.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Dissociation', label: 'Dissociation', render: (Datasets: any) => Datasets.Dissociation, isVisible: true, width: 82.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Files', label: 'Files', render: (Datasets: any) => Datasets.Files, isVisible: true, width: 82.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Infusion', label: 'Infusion', render: (Datasets: any) => Datasets.Infusion, isVisible: true, width: 82.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Patient', label: 'Patient', render: (Datasets: any) => Datasets.Patient, isVisible: true, width: 82.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Protocol', label: 'Protocol', render: (Datasets: any) => Datasets.Protocol, isVisible: true, width: 242.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Sample', label: 'Sample', render: (Datasets: any) => Datasets.Sample, isVisible: true, width: 82.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'SequencingDate', label: 'Sequencing Date', render: (Datasets: any) => Datasets.SequencingDate, isVisible: true, width: 82.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Single/dual', label: 'Single/dual', render: (Datasets: any) => Datasets['Single/dual'], isVisible: true, width: 82.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Stage', label: 'Stage', render: (Datasets: any) => Datasets.Stage, isVisible: true, width: 82.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Time', label: 'Time', render: (Datasets: any) => Datasets.Time, isVisible: true, width: 82.7 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) &&{ Id: 'Sample_ID', label: 'Sample_ID', render: (Datasets: any) => Datasets.SampleId, isVisible: true, width: 100 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Group', label: 'Group', render: (Datasets: any) => Datasets.Group, isVisible: true, width: 100 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Selection Protocol', label: 'Selection Protocol', render: (Datasets: any) => Datasets['SelectionProtocol'], isVisible: true, width: 150 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) &&{ Id: 'Replicate', label: 'Replicate', render: (Datasets: any) => Datasets.Replicate, isVisible: true, width: 100 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) &&{ Id: 'Cell Genotype', label: 'Cell Genotype', render: (Datasets: any) => Datasets['CellGenotype'], isVisible: true, width: 150 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) &&{ Id: 'Background', label: 'Background', render: (Datasets: any) => Datasets.Background, isVisible: true, width: 100 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) &&{ Id: 'Selection Marker', label: 'Selection Marker', render: (Datasets: any) => Datasets['SelectionMarker'], isVisible: true, width: 150 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Source', label: 'Source', render: (Datasets: any) => Datasets.Source, isVisible: true, width: 100 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) &&{ Id: 'Catalog#', label: 'Catalog#', render: (Datasets: any) => Datasets['Catalog#'], isVisible: true, width: 100 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) &&{ Id: 'Condition', label: 'Condition', render: (Datasets: any) => Datasets.Condition, isVisible: true, width: 100 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'NumberOfCellsSequenced', label: 'Number Of Cells Sequenced', render: (Datasets: any) => Datasets['NumberOfCellsSequenced'], isVisible: true, width: 150 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) &&{ Id: 'Pmi(hr)', label: 'Pmi(hr)', render: (Datasets: any) => Datasets['Pmi(hr)'], isVisible: true, width: 100 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Rin', label: 'Rin', render: (Datasets: any) => Datasets.Rin, isVisible: true, width: 100 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Brain Weight (gr)', label: 'Brain Weight (gr)', render: (Datasets: any) => Datasets['BrainWeight(gr)'], isVisible: true, width: 150 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) &&{ Id: 'AD Stage', label: 'AD Stage', render: (Datasets: any) => Datasets['AdStage'], isVisible: true, width: 100 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) && { Id: 'Other Pathology', label: 'Other Pathology', render: (Datasets: any) => Datasets.OtherPathology, isVisible: true, width: 150 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) &&{ Id: 'BiomaterialProvider', label: 'Biomaterial Provider', render: (Datasets: any) => Datasets['BiomaterialProvider'], isVisible: true, width: 150 }),
            (["Alzheimer's Disease", "Healthy"].includes(diseaseType as string) &&{ Id: 'Diet', label: 'Diet', render: (Datasets: any) => Datasets.Diet, isVisible: true, width: 100 })
           
        ].filter(Boolean) as any);
    }, [diseaseCategory, diseaseType]);

    return <CurationTable config={config} curating="sample" navButtons={["Study", "Experiment"]} width={width} rowkey="SampleAccessionId"/>;
}

export default SampleLevelCurationTable;