import React, { useState,useEffect  } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';
import { Checkbox, CircularProgress } from '@mui/material';
import WrapCell from './WrapCell';
import { selectUser } from "../store/slices/userSlice";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import { selectDiseaseCategory, selectDiseaseType } from "../store/slices/diseaseCategorySlice";
import {  selectFilters } from "../store/slices/filtersSlice";
import { selectSearchKey } from "../store/slices/searchSlice";

interface Column {
    Id: string;
    label: string;
    render: (rowData: any) => React.ReactNode;
    isVisible: boolean;
    width: number;
}

interface RowData {
    [key: string]: string | number;
}

interface MUITableProps {
    fetchFunction: (page: number, perPage: number) => void;
    totalRows: number;
    table: RowData[];
    columns: Column[];
    width: number;
    rowkey: string;
    selectedRows: string[] | object;
    handleNavigation: (newSelectedRows: string[] | object) => void;
    apiParams:any|null;
    curation:any|null;
}

function DataTable({totalRows,fetchFunction, table, columns, width, rowkey, selectedRows, handleNavigation,apiParams,curation }: MUITableProps)
 {
    const [orderBy, setOrderBy] = useState<string>(rowkey === "ReferenceDataset" ? "YearOfPublication" : rowkey);
    const [order, setOrder] = useState<'asc' | 'desc' | 'original'>(["order_id","ReferenceDataset"].includes(rowkey) ? 'original' : 'asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [selectAll, setSelectAll] = useState(false);
    const [sampleCount, setSampleCount] = useState<number | null>(null);
    const [cellCount, setCellCount] = useState<number | null>(null);
    const [cellTypeCount, setCellTypeCount] = useState<number | null>(null);
    const diseaseCategory = useSelector(selectDiseaseCategory);
    const diseaseType = useSelector(selectDiseaseType);
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedFilters = useSelector(selectFilters);
    const searchKey = useSelector(selectSearchKey);

    const [isSticky, setIsSticky] = useState(false);
    const tableContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            const tableContainer = tableContainerRef.current;
            if (tableContainer) {
                if (tableContainer.scrollLeft > 0) {
                    setIsSticky(true); 
                } else {
                    setIsSticky(false); 
                }
            }
        };

        const tableContainer = tableContainerRef.current;
        if (tableContainer) {
            tableContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableContainer) {
                tableContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    
    useEffect(() => {
        fetchFooterDetails();
    }, [diseaseCategory, diseaseType]);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectAll(!selectAll);
            handleNavigation(rowkey === "SampleAccessionId" ? {} : []);
        }else {
            setSelectAll(!selectAll);
            let newSelectedRows: any;
            if (rowkey === "SampleAccessionId") {
                table.forEach((row) => {
                    newSelectedRows = {...newSelectedRows,[row[rowkey]]:row["GeoAccessionId"]};
                });
            }else {
            newSelectedRows = table.map((row) => { return row['GEOAccession'] } );
            }
            handleNavigation(newSelectedRows);
        }
    }

    const handleRowSelection = (rowKey: string, rowData: any) => {
        let newSelectedRows;
        if ("SampleAccessionId" in rowData) {
            newSelectedRows = rowData[rowkey] in selectedRows ? Object.fromEntries(Object.entries(selectedRows).filter(([key]) => key !== rowData[rowkey])) : {...selectedRows, [rowKey]: rowData["GeoAccessionId"] };
            setSelectAll(table.length === Object.keys(newSelectedRows).length ? true : false);
        }else {
            newSelectedRows = (selectedRows as string[]).includes(rowData['GEOAccession']) ? (selectedRows as string[]).filter((key) => key !== rowData['GEOAccession']) : [...(selectedRows as string[]), rowData['GEOAccession']];
            setSelectAll(table.length === newSelectedRows.length ? true : false);
        }
        handleNavigation(newSelectedRows);
    };

    const handleSort = (columnId: string) => {
        if (orderBy === columnId) {
            if (order === 'asc') {
                setOrder('desc');
            }else if (order === 'desc') {
                setOrder('original');
            }else {
                setOrder('asc');
            }
        }else {
            setOrderBy(columnId);
            setOrder('asc');
        }
    };

    const sortedTable = [...table].sort((a, b) => {
        if (orderBy === '' || order === 'original') {
            return 0;
        }
        const aValue = a[orderBy];
        const bValue = b[orderBy];
        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return order === 'asc' ? (aValue - bValue) : -(aValue - bValue);
        }else {
            return order === 'asc' ? String(aValue).localeCompare(String(bValue)) : String(bValue).localeCompare(String(aValue));
        }
    });

    const renderedHeaders = (
        <TableRow>{ sortedTable.length > 0 && !(["order_id","ReferenceDataset"].includes(rowkey)) ? 
            <TableCell
            style={{
                position: 'sticky',
                left: 0, 
                zIndex: 2, 
                background: '#fff', 
            }}
        >

                <span style={{display: 'flex', alignItems: 'center', marginBottom: '-5px', marginTop: '-6px'}}>
                    <Checkbox size="small" style={{ cursor: 'pointer', transform: "scale(0.95)", padding: 0, color: selectAll ? "#0C73EB" : "gray" }} checked={selectAll} onChange={handleSelectAll}/>
                </span>
            </TableCell> : null }
        {columns.map((column) => (
            <TableCell
            key={column.Id}
            onClick={() => handleSort(column.Id)}
            style={
                
                sortedTable.length > 0 
            ? {
                  cursor: 'pointer',
                  position: ['GEOAccession', 'GeoAccessionId', 'SampleAccessionId'].includes(column.Id)
                      ? 'sticky'
                      : 'relative',
                  left: column.Id === 'SampleAccessionId'
                      ? '11.5rem'
                      : ['GEOAccession', 'GeoAccessionId'].includes(column.Id)
                      ? '40px'
                      : '0px',
                  backgroundColor: ['GEOAccession', 'GeoAccessionId', 'SampleAccessionId'].includes(column.Id)
                      ? '#fff'
                      : undefined,
                  zIndex: ['GEOAccession', 'GeoAccessionId', 'SampleAccessionId'].includes(column.Id) ? 2 : undefined,
                  whiteSpace: 'nowrap',
                  paddingTop: '8px',
                  paddingBottom: '8px',                
              }
            : {} 
    }>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <b>{column.label}</b>
                    {orderBy === column.Id && (
                    order === 'original' || (order === 'asc' ? 
                        <span style={{ marginBottom:'-5px', marginLeft: '3px' }}><GoArrowUp size={16}/></span> : 
                        <span style={{ marginBottom:'-5px', marginLeft: '3px' }}><GoArrowDown size={16}/></span>
                    ))}
                </div>
            </TableCell>
        ))}
        </TableRow> 
    );

    const renderWrappedCell = (column: any) => {
        return typeof column === 'string' ? <WrapCell column={column}/> : column ;
    };

    const renderedRows = sortedTable.map((rowData, index) => {
        const alternatingColor = index % 2 === 0 ? '#f1f3f6' : 'white';
        let isSelected;
        if (!(["order_id","ReferenceDataset"].includes(rowkey))) {
            isSelected = ("SampleAccessionId" in rowData) ? (rowData[rowkey].toString() in selectedRows) : (selectedRows as string[]).includes(rowData['GEOAccession'].toString());
        }
        const renderedCells = columns.map((column) => (
            <TableCell
            key={column.Id}
            style={{
                position: ['GEOAccession', 'GeoAccessionId', 'SampleAccessionId'].includes(column.Id) ? 'sticky' : 'relative',
                left: (column.Id === 'SampleAccessionId'
                    ? '11.5rem'
                    : ['GEOAccession', 'GeoAccessionId'].includes(column.Id)
                    ? '2.5rem'
                    : '0px'),                
                    backgroundColor: ['GEOAccession', 'GeoAccessionId', 'SampleAccessionId'].includes(column.Id) && isSticky ? '#ffff' : undefined,
                zIndex: ['GEOAccession', 'GeoAccessionId', 'SampleAccessionId'].includes(column.Id) && isSticky ? 1 : undefined,
                paddingTop: '8px',
                paddingBottom: '8px',
               minWidth: column.width, 
               maxWidth: 'auto',
            }}
        >

                {column.render(rowData) === '' || column.render(rowData) === null ? "null" : renderWrappedCell(column.render(rowData)) }
            </TableCell>
        ));

        return (
            <TableRow style={{ backgroundColor: alternatingColor}} key={rowData[rowkey]}>
                { !(["order_id","ReferenceDataset"].includes(rowkey)) ? 
                <TableCell   style={{
                    position: 'sticky',
                    left: 0, 
                    zIndex:1,
                    backgroundColor:  isSticky ? '#ffff' : '',
                }}>
                    <Checkbox size="small" style={{ cursor: 'pointer', transform: "scale(0.95)", padding: 0, color: isSelected ? "#0C73EB" : "gray" }} checked={isSelected} onChange={() => handleRowSelection(rowData[rowkey].toString(),rowData)}/>
                </TableCell> : null }
                {renderedCells}
            </TableRow>);
    });
  
    const [loading, setLoading] = useState(false);
    const [loadNumber,setloadNumber]=useState(true);

    const handleFetchFunction = async (page: number, perPage: number) => {
        setLoading(true);
        if (rowkey === "order_id" || rowkey === "ReferenceDataset") {
            await fetchFunction(page, perPage);
            setLoading(false);
            return;
        }
        setSelectAll(false);
        handleNavigation(rowkey === "SampleAccessionId" ? {} : []);
        await fetchFunction(page, perPage);
        setLoading(false);
    }

     const fetchFooterDetails = async (page: number = 1, perPage: number = 15) => {
        let query=''
        if (apiParams?.page_name === "public_dataset") 
        {
           query = `/api/footer_detail?page_name=Public Dataset`;
        }
        else if(apiParams?.page_name === "curation_request")
            return;
        else{
        query = (searchKey.length > 0) ? `/api/${curation}_search?page=${page}&per_page=${perPage}&search_string=${encodeURIComponent(searchKey)}&DiseaseCategory=${diseaseCategory}&` : 
                    `/api/${curation}?page=${page}&per_page=${perPage}&DiseaseCategory=${diseaseCategory}&`;
        if (Object.values(selectedFilters).some((value) => value.length > 0)) {
            Object.keys(selectedFilters).forEach(filter => {
                selectedFilters[filter].forEach(value => {
                    query += `${filter}=${encodeURIComponent(value)}&`;
                });
            });
        }
        query = query.slice(0,-1);
        if (diseaseCategory !== "Inflammatory Bowel Disease") {
            query += `&DiseaseType=${diseaseType}`;
        }
    }
            try {
                const response = await axiosConfig.get(query);
                        const res = apiParams?.page_name === "public_dataset" 
                            ? response.data
                            : response.data.footer_detail;

                        setSampleCount(res.no_of_samples);
                        setCellCount(res.no_of_cells);
                        setCellTypeCount(res.no_of_distinct_cell_types);
            }catch(error: any) {
                try {
                    if (error.response.data.msg === "Token has been revoked") {
                        handleLogout(dispatch, navigate);
                    } 
                    if (error.response.data.msg === "Token has expired") {
                        const response = await handleRefresh(loggedUser.refreshToken);
                        refreshAccessToken(dispatch, loggedUser, response);
                        const refreshedResponse = await axiosConfig.get(query);
                        const res = apiParams?.page_name === "public_dataset" 
                            ? refreshedResponse.data
                            : refreshedResponse.data.footer_detail;

                        setSampleCount(res.no_of_samples);
                        setCellCount(res.no_of_cells);
                        setCellTypeCount(res.no_of_distinct_cell_types);
                    }
                }catch(error: any) {
                    if (error.response.data.msg === "Token has expired") {
                        handleLogout(dispatch, navigate);
                    }
                }
            }
            finally
            {
                setloadNumber(false);
            }
        };

    return (
    <Paper >
    {loading && 
        <CircularProgress size={50} thickness={4.5} style={{ position: 'absolute', top: '45%', left: '50%', color: '#0C73EB' }}/>
    }
    <TableContainer ref={tableContainerRef} 
        className="customScrollbar"
        sx={{
            flex: 1, 
            overflowY: 'auto', 
            maxHeight: 'calc(100vh - 160px)', 
            '&::-webkit-scrollbar': { displayWebkitAppearance: 'none' }
        }}
    >
        <Table sx={{ minWidth: width }}>
            <TableHead sx={{ position: 'sticky', zIndex: 2, top: 0, backgroundColor: 'white' }}>
                {renderedHeaders}
            </TableHead>
            <TableBody>{renderedRows}</TableBody>
        </Table>
    </TableContainer>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '10px',marginTop:'2px' }}>

    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '8px' }}>
            <span style={{ marginRight: '12px' }}>
        <strong>
            {apiParams!=null?(apiParams.page_name === 'curation_request' ? " " :"Total Dataset Details"):(diseaseCategory === "Neurodegenerative Disease"? diseaseType :diseaseCategory)}
        </strong>
            </span>
        </div>
        {loadNumber ? (
           ((apiParams!=null)&&(apiParams.page_name === 'curation_request'))||<div>Loading...</div> 
        ) : (sampleCount !== null && cellCount !== null && cellTypeCount !== null && (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <span style={{ marginRight: '12px' }}>
                <strong>Total Number of Samples:</strong> {sampleCount}
            </span>
            <span style={{ marginRight: '12px' }}>
                <strong>Total Number of Cells:</strong> {cellCount}
            </span>
            <span style={{ marginRight: '12px' }}>
                <strong>Total Number of Distinct Cell Types:</strong> {cellTypeCount}
            </span>
        </div>))}
         </div>

    {/* Right Section: Pagination */}
    <div>
        <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => {
                setPage(newPage);
                handleFetchFunction(newPage + 1, rowsPerPage);
            }}
            onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
                handleFetchFunction(1, parseInt(event.target.value, 10));
            }}
        />
    </div>
</div>

</Paper>

    );
}

export default DataTable;
