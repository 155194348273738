import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Divider, IconButton, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import logo from "../companylogo.png";
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../store/slices/userSlice';
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from '../axiosConfig';
import SearchBar from './SearchBar';
import { useState } from "react";
import Curation_icon from "../images/CurationReq_icon.svg"
import Meta_icon from "../images/Meta_icon.svg"
import arrow from "../images/iconamoon_arrow-up-2-bold (1).svg"
import profile from "../images/Profile.svg"
import logout from "../images/Logout.svg"
import { resetFilters } from '../store/slices/filtersSlice';
import { updateDiseaseCategory } from '../store/slices/diseaseCategorySlice';
import Datasets_icon from "../images/Dataset.svg"

const linkStyles: React.CSSProperties = { textDecoration: 'none', color: 'black', cursor: 'pointer' };
const menuStyle = { "& .MuiPaper-root": { width: 250, borderRadius: "12px", border: "1px solid #E2E5F9", padding: "2px 0", boxShadow: "2px 4px 10px rgba(0,0,0,0.1)" } };
const menuItemStyles = { width: "254px", height: "47px", paddingRight: "50px", "&:hover": { backgroundColor: "#F4F4F9" } };
const menuStyle1 = { ...menuStyle, ml: 2, mt: 2 };
const menuStyle2 = { ...menuStyle, ml: 8, mt: 3};
function LogoutMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const handleLogoutButton = async () => {
        try {
            const response = await axiosConfig.post('/api/logout');
            if (response.data.status === "SUCCESS") {
                handleLogout(dispatch,navigate);
            }
        }catch(error: any) {
            try{
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    if((await axiosConfig.post('/api/logout')).data.status === "SUCCESS") {
                        handleLogout(dispatch, navigate);
                    }
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch,navigate);
                }
            }
        }
    }
    return (
        <React.Fragment>
           
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={handleClick}>
            <IconButton onClick={handleClick} size="medium" aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
                <Avatar sx={{ bgcolor: "#CCCFEA", width: 35, height: 30, fontSize: '1rem', fontWeight: 'bold',color:"black" }}>
                  {String(loggedUser.username)[0].toUpperCase()}
                </Avatar>
            </IconButton>
            <Typography sx={{ fontSize: "0.875rem", fontWeight: 500, color: "#000", whiteSpace: "nowrap",  "@media (max-width: 1200px)": { display: "none" }, }}
             onMouseEnter={(e) => (e.target as HTMLElement).style.color = "#364098"} 
             onMouseLeave={(e) => (e.target as HTMLElement).style.color = "#000"}>
                My profile
                <img src={arrow} alt="down-arrow"></img>
            </Typography>
        </Box>

            <Menu anchorEl={anchorEl} id="logoutmenu" open={open} onClose={handleClose} onClick={handleClose} transformOrigin={{ horizontal: "right", vertical: "top" }} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
             sx={menuStyle1}>
                
    <MenuItem sx={menuItemStyles} onClick={handleClose}>
                <Avatar 
    sx={{ 
        bgcolor: "#CCCFEA", 
        width: "45", 
        height: "44", 
        fontSize: '18', 
        fontWeight: 'bold', 
        color: "black", 
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }}
>
    {String(loggedUser.username)[0].toUpperCase()}
</Avatar>

                    
                    <ListItemText primary={loggedUser.username} secondary={loggedUser.email}/>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={() => { navigate('/user-profile'); }} sx={{paddingRight: '50px',width:"254px",height:"47px", '&:hover': { backgroundColor: '#F4F4F9' }}}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <img src={profile} width="14px" height="14px" alt='profile_icon' ></img>
                    <Link key="profile" to={'/user-profile'} style={linkStyles}>My Profile</Link>
                </div>
                </MenuItem>
                <MenuItem onClick={handleLogoutButton} sx={{paddingRight: '50px',width:"254px",height:"47px", '&:hover': { backgroundColor: '#F4F4F9' },}} >
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <img src={logout} width="14px" height="14px" alt='logout_icon'></img>
                    <Link key="Logout" to={'/login'} style={linkStyles}>Logout</Link>
                </div>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}


function NavigationAppBar({ includeSearch = false }: any) {
  const dispatch=useDispatch();

  const handleDiseaseCategoryChange = (selectedDiseaseCategory: string|null) => {
    dispatch(resetFilters());
    dispatch(updateDiseaseCategory(selectedDiseaseCategory));
  };

    const [activeLink, setActiveLink] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickCuration = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (linkName: string) => {
        setActiveLink(linkName); 
    };

    const getLinkStyle = (linkName: string) => ({
        color: activeLink === linkName ? "#364098" : "black",
        cursor:"pointer",
        whiteSpace: "pre",
        letterSpacing: "-0.02em",
        fontWeight: 500,
        fontSize: "1rem",
    
    });
    const navigate = useNavigate();
    return (
<Box sx={{ width: "100%" }}>
  <AppBar
    position="fixed"
    sx={{
      backgroundColor: "white",
      borderBottom: "1px solid #e2e5f9",
      boxShadow: "2px 2px 4px -2px rgba(0, 0, 0, 0.25)",
      minHeight:'55px'
    }}
  >
    <Toolbar sx={{ minHeight: "80px" }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          marginLeft: 'auto',
          marginRight: 0
        }}
      >
        {/* Left part */}
        <div style={{display:'flex',flexDirection:'row'}}>
        
     
      <img
          onClick={() => navigate('/')}
          style={{
            minWidth:'100px',
            width:'8vw',
            height: '30px',
            marginBottom: '4px',
            cursor: 'pointer',
          }}
          src={logo}
          alt="company logo"
        />
        <div style={{ paddingLeft:"11px",paddingRight:"20px",display:"flexStart",flexDirection:"column",whiteSpace: "nowrap",minWidth: "10vw" }}>
          <div
            className="font-og"
            style={{
              fontSize: "0.875rem",
              fontWeight: 600,
              letterSpacing: "-0.02rem",
              color: "rgba(36, 36, 36, 1)",
            }}
          >
            ScRNA sequencing
          </div>
          <div
            style={{
            
              marginTop:"-2px",
              fontSize: "0.875rem",
              fontWeight: 400,
              letterSpacing: "-0.02rem",
              color: "rgba(36, 36, 36, 1)",
            }}
          >
            Data curation portal v1.0
          </div>
        </div>
        </div>
      
 {/* Search Bar - Middle Part */}
 <div
    style={{
      flexGrow: 1,
      display: "flex",
         }}
  >
    {includeSearch ? <SearchBar /> : null}
  </div>
  <Divider orientation="vertical" sx={{marginRight:'1.5vw',marginLeft:'1.5vw'}} flexItem />

        
        <Stack
          direction="row"
          alignItems="center"
          spacing={2.2}
          sx={{ height: "100%"}}
        >
           
      
    <Link 
     className='hide-on-small'
        to="/study-table"  
        style={getLinkStyle("datasets")}  
        onMouseEnter={() => handleClick("datasets")} 
        onMouseLeave={() => setActiveLink("")}
        onClick={(e) => { 
            handleDiseaseCategoryChange("Inflammatory Bowel Disease");
        }}
    >
        <img 
            src={Datasets_icon} 
            style={{ marginRight: "5px"}} 
            alt="icon"
        />
        Datasets
    </Link>

          <Divider orientation="vertical" flexItem />
         
              <Link className='hide-on-small' style={getLinkStyle("metadata")}  to={"/metadata-visualization"}
              onMouseEnter={() => handleClick("metadata")} 
              onMouseLeave={() => setActiveLink("")}
              onClick={(e) => { 
                handleDiseaseCategoryChange("Inflammatory Bowel Disease");
            }}>
              <img src={Meta_icon} style={{ marginRight: "5px" }} alt='meta' />
               Metadata Visualisation
              </Link>
          <Divider orientation="vertical" flexItem />
                            <b style={getLinkStyle("curation")}
                            onMouseEnter={() => handleClick("curation")} 
                            onMouseLeave={() => setActiveLink("")}
                             onClick={handleClickCuration}>
                            <img src={Curation_icon} alt="icon" style={{ marginRight: "5px" }} />
                            Curation Request
                            <img src={arrow} alt="arrow"/>

                            </b>
                            
                            <Menu anchorEl={anchorEl} id="curationmenu" open={open} onClose={handleClose} onClick={handleClose} transformOrigin={{ horizontal: "right", vertical: "top" }} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
             sx={menuStyle2}>
                <Link to={"/create-curation-request"} style={linkStyles}>
                  <MenuItem sx={menuItemStyles}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    Create Curation Request
                  </div>
                  </MenuItem>
                </Link>    

                <Link to={"/curation-requests"} className='hide-on-small' style={linkStyles}>
                  <MenuItem  sx={{paddingRight: '50px',width:"254px",height:"47px", '&:hover': { backgroundColor: '#F4F4F9' },}} >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                     My Curation Requests
                  </div>
                  </MenuItem>
                </Link>
            </Menu>
            <Divider orientation="vertical" flexItem />
          <LogoutMenu />
        </Stack>
        </div>
    </Toolbar>
  </AppBar>
</Box>

    );
}

export default NavigationAppBar;
