import logo from "../companylogo.png";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { axiosConfig } from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import { TbReload } from "react-icons/tb";
import { loadCaptchaEnginge, validateCaptcha, LoadCanvasTemplateNoReload } from "react-simple-captcha";


type FormValue = {
    email: string
    phoneNumber: string
    captcha: string
}

function ForgotPassword() {
    useEffect(() => {
        loadCaptchaEnginge(6);
    },[]);

    const form = useForm<FormValue>({
        defaultValues: {
            email: '', phoneNumber: '', captcha: '',
        }
    });
    const navigate = useNavigate();
    const { register, handleSubmit, reset, formState, setError } = form;
    const { errors } = formState;
    const [feedback, setFeedback] = useState<string>('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const onSubmit = async (data: FormValue) => {
        try {
            setDisableSubmit(true);
            const response = await axiosConfig.post('/api/forget_password', {"email": data.email});
            if (response.data.status === "SUCCESS") {
                setDisableSubmit(false);
                setFeedback("Password successfully sent to email");
                setTimeout(() => { 
                    navigate('/login');
                    reset();
                }, 1000);
            }
        }catch(error: any) {
            if (error.response.data.data === "User not found") {
                setDisableSubmit(false);
                setError("email", { type: "required", message: "User not found" });
                setError("phoneNumber",{ type: "required", message: "User not found" });
            }
        }
    };
    return (

        <div className="modal-overlay-visible">
        <div className="modal-background"></div>
        <div className="modal-content-visible bg-white rounded-[3vw] md:rounded-[.85vw] px-[4vw] md:px-[2.5vw] py-[5vw] md:py-[2.5vw] w-[90vw] md:w-[37vw] relative">
      
            {/* Modal Content */}            
            <div className="font-og">
                <div className="flex justify-center">
                    <a href="/">
                    <img src={logo} className="w-[25vw] md:w-[10vw]" alt="Logo" />
                    </a>               
                </div>
                <div className="mt-[6vw] md:mt-[3vw]">
                <p className="text-[5vw] md:text-[2.5vw] leading-[1] text-center">
                    Forgot Password <br /> 
                </p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-[3vw] md:gap-[1vw] mt-[7vw] md:mt-[4vw]">
                <div className="relative">
                    <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                    <input
                type="email"
                {...register("email", {
                    required: {
                        value: true,
                        message: "Enter email Id",
                    },
                    pattern: {
                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: "Enter a valid email",
                    }
                })}
                placeholder="Email ID"
                className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
            />
            </div>
            {errors.email && <span style={{ color: 'red' }}>{errors.email.message}</span>}
        </div>
        <div style={{display:'flex'}}>
                                        <LoadCanvasTemplateNoReload/>
                                        <div style={{cursor:'pointer'}} onClick={() => loadCaptchaEnginge(6)}><TbReload/></div>
                                    </div>
            <div className="relative">
                        <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                            <input
                            maxLength={6} // Limit captcha to 6 characters
                            autoComplete="off"
                            {...register('captcha', {
                                required: 'Enter the captcha text',
                                validate: (userCaptcha) =>
                                validateCaptcha(userCaptcha, false) || 'Captcha does not match', // Custom CAPTCHA validation function
                            })}
                            placeholder="Enter Captcha"
                            className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
                            />
                        </div>
                        {errors.captcha && <span style={{ color: 'red' }}>{errors.captcha.message}</span>}
                        </div>
                <div className="relative mt-[2vw]">
                    <button
                    type="submit"
                    className="rounded-[2vw] md:rounded-[.5vw] overflow-hidden bg-black text-white w-full text-[4vw] md:text-[1.1vw] px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw]"
                    disabled={disableSubmit}
                    >
                    <p>Continue</p>
                    </button>
        </div>
                </form>

                <div className="mt-[5vw] md:mt-[2vw]">
                <div className="flex justify-center text-[3.25vw] md:text-[1.1vw] gap-[1.5vw] md:gap-[.5vw]">
                    Click here to Log in to scRNA Portal
                    <button onClick={() => { navigate('/login'); }} className="open-modal-button border border-black text-[3vw] md:text-[.85vw] leading-[1] rounded-full px-[2vw] md:px-[.75vw] py-[.75vw] md:py-[.2vw]">
                    Log in
                    </button>
                </div>
                </div>
               
            </div>
            </div>
    </div>
    );
}

export default ForgotPassword;