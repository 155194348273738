import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateDiseaseCategory,updateDiseaseType } from "../store/slices/diseaseCategorySlice";
import { resetFilters } from "../store/slices/filtersSlice";
import { Link } from "react-router-dom";
import { axiosConfig } from "../axiosConfig";

interface DiseaseDropdownProps {
  diseasesWithoutSubtypes: string[];
  diseasesWithSubtypes: string[];
  diseaseSubtypeToCategory: Record<string, string>;
}

const DiseaseDropdown: React.FC<DiseaseDropdownProps> = ({
  diseasesWithoutSubtypes,
  diseasesWithSubtypes,
  diseaseSubtypeToCategory,
 
}) => {

  const dispatch=useDispatch();

  const [activeMainMenu, setActiveMainMenu] = useState<string | null>(null);
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);


  const handleDiseaseCategoryChange = (selectedDiseaseCategory: string) => {
    dispatch(resetFilters());
    dispatch(updateDiseaseCategory(selectedDiseaseCategory));
  };

  const handleDiseaseTypeChange = (selectedDiseaseType: string) => {
    dispatch(resetFilters());
    dispatch(updateDiseaseType(selectedDiseaseType));
  };

const toggleMainMenu = (disease: string) => {
  setActiveMainMenu(disease);
  setActiveSubMenu(null);
};

const toggleSubMenu = (subMenu: string) => {
  setActiveSubMenu(subMenu);
};

const closeMainMenu = () => {
  setActiveMainMenu(null);
  setActiveSubMenu(null);
};

return (
  <div>
    {/* Diseases Without Subtypes */}
    {diseasesWithoutSubtypes.map((disease, index) => (
      <div
        key={index}
        className="relative"
        onMouseEnter={() => toggleMainMenu(disease)}
        onMouseLeave={closeMainMenu}
      >
        <div className="px-[1vw] flex items-center cursor-pointer justify-between py-[.5vw] w-full duration-300 hover:bg-gray-50">
          <p>{disease}</p>
          <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[90deg] text-[#F14234]" viewBox="0 0 24 24">
            <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
          </svg>
        </div>
        {activeMainMenu === disease && (
          <div className="left-full w-max whitespace-nowrap absolute top-0 flex flex-col duration-300 bg-white shadow">
            <div
              className="relative"
              onMouseEnter={() => toggleSubMenu('Single Cell RNA Seq')}
              onMouseLeave={closeMainMenu}
            >
              <div className="px-[1vw] gap-[.25vw] py-[.5vw] duration-300 hover:bg-gray-50 cursor-pointer flex justify-between items-center">
                <p>Single Cell RNA Seq</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[90deg] text-[#F14234]" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
                </svg>
              </div>
              {activeSubMenu === 'Single Cell RNA Seq' && (
                <div className="left-full w-max whitespace-nowrap absolute top-0 flex flex-col duration-300 bg-white shadow">
                  <Link
                    to="/study-table"
                    className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                    onClick={() => handleDiseaseCategoryChange(disease)}
                  >
                    Study Level Curation
                  </Link>
                  <Link
                    to="/experiment-table"
                    className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                    onClick={() => handleDiseaseCategoryChange(disease)}
                  >
                    Experiment Level Curation
                  </Link>
                  <Link
                    to="/sample-table"
                    className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                    onClick={() => handleDiseaseCategoryChange(disease)}
                  >
                    Sample Level Curation
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    ))}

    {/* Diseases With Subtypes */}
    {diseasesWithSubtypes.map((disease, index) => (
      <div
        key={index}
        className="relative"
        onMouseEnter={() => toggleMainMenu(disease)}
        onMouseLeave={closeMainMenu}
      >
        <div className="px-[1vw] flex items-center cursor-pointer justify-between py-[.5vw] w-full duration-300 hover:bg-gray-50">
          <p>{disease}</p>
          <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[90deg] text-[#F14234]" viewBox="0 0 24 24">
            <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
          </svg>
        </div>
        {activeMainMenu === disease && (
          <div className="left-full w-max whitespace-nowrap absolute top-0 flex flex-col duration-300 bg-white shadow">
            <div
              className="relative"
              onMouseEnter={() => toggleSubMenu('Single Cell RNA Seq')}
              onMouseLeave={closeMainMenu}
            >
              <div className="px-[1vw] gap-[.25vw] py-[.5vw] duration-300 hover:bg-gray-50 cursor-pointer flex justify-between items-center">
                <p>Single Cell RNA Seq</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[90deg] text-[#F14234]" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
                </svg>
              </div>
              {activeSubMenu === 'Single Cell RNA Seq' && (
                <div className="left-full w-max whitespace-nowrap absolute top-0 flex flex-col duration-300 bg-white shadow">
                  <Link
                    to="/study-table"
                    className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                    onClick={() => {
                      const category = diseaseSubtypeToCategory[disease];
                      handleDiseaseCategoryChange(category);
                      handleDiseaseTypeChange(disease);
                    }}
                  >
                    Study Level Curation
                  </Link>
                  <Link
                    to="/experiment-table"
                    className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                    onClick={() => {
                      const category = diseaseSubtypeToCategory[disease];
                      handleDiseaseCategoryChange(category);
                      handleDiseaseTypeChange(disease);
                    }}
                  >
                    Experiment Level Curation
                  </Link>
                  <Link
                    to="/sample-table"
                    className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50"
                    onClick={() => {
                      const category = diseaseSubtypeToCategory[disease];
                      handleDiseaseCategoryChange(category);
                      handleDiseaseTypeChange(disease);
                    }}
                  >
                    Sample Level Curation
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    ))}
  </div>
);
};
export default DiseaseDropdown;