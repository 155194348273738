import { useState } from "react";
import logo from "../companylogo.png";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { axiosConfig } from "../axiosConfig";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { login } from "../store/slices/userSlice";
import { hashSync } from "bcrypt-ts";
import { Link } from "react-router-dom";

type FormValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  closeButton?: boolean;
  signupNav?:boolean;
  customClassNames?: {
    overlay?: string;
    content?: string;
  };
};

const LoginForm: React.FC<LoginFormProps> = ({
  closeButton = true,
  customClassNames = {},
  signupNav=true,

}) => {
  const schema = yup.object({
    email: yup.string().email('Enter a valid email').required('Enter an email'),
    password: yup.string().required('Enter a password'),
  });

  const form = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, formState, setError } = form;
  const { errors } = formState;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const onSubmit = async (data: FormValues) => {
    try {

      setDisableSubmit(true);
      const passwordHash = hashSync(data.password, process.env.REACT_APP_API_SALT!);
      const response = await axiosConfig.post('/api/login', { email: data.email, user_password: passwordHash });

      if (response.data.status === 'SUCCESS') {
        dispatch(login({
          username: response.data.username,
          email: data.email,
          isAuthenticated: true,
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
        }));
        const modal = document.getElementById('loginModal');
        if (modal) {
          modal.classList.remove('show');
        }
      } else if (response.data.data === 'Password change is required') {
        dispatch(login({
          username: null,
          email: response.data.email,
          isAuthenticated: false,
          accessToken: null,
          refreshToken: null,
        }));
        navigate('/change-password', { replace: true });
      }
    } catch (error: any) {
      if (error?.response?.data?.data === 'Invalid credentials') {
        setDisableSubmit(false);
        setError('password', { type: 'required', message: 'Invalid credentials' });
        setError('email', { type: 'required', message: 'Invalid credentials' });
      }
    } finally {
      setDisableSubmit(false);
    }
  };

    return (
    <div id="loginModal" className={`modal-overlay hidden ${customClassNames.overlay || ""}`}>
    <div className='modal-background'></div>
    <div
      className={`modal-content bg-white rounded-[3vw] md:rounded-[.85vw] px-[4vw] md:px-[2.5vw] py-[5vw] md:py-[2.5vw] w-[90vw] md:w-[37vw] relative ${
        customClassNames.content || ""
      }`}
    >
      {closeButton && (
        <button
          data-modal-close="loginModal"
          className={`close-modal-button absolute top-[2vw] right-[2vw] 
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
            <path
              fill="#000000"
              d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
            />
          </svg>
        </button>)}

        <div className="font-og">
            <div className="flex justify-center">
            <a href="/">
                <img src={logo} className="w-[25vw] md:w-[10vw]" alt="Logo" />
            </a>
            </div>
            <div className="mt-[6vw] md:mt-[3vw]">
            <p className="text-[5vw] md:text-[2.5vw] leading-[1] text-center">
                Log in to ScRNA data <br /> curation portal v1.0
            </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-[3vw] md:gap-[1vw] mt-[7vw] md:mt-[4vw]">
            <div className="relative">
                <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                <input
            type="email"
            {...register("email", {
                required: {
                    value: true,
                    message: "Enter email Id",
                },
                pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Enter a valid email",
                }
            })}
            placeholder="Email ID"
            className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
        />
        </div>
        {errors.email && <span style={{ color: 'red' }}>{errors.email.message}</span>}
    </div>
            <div className="relative">
                <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                <input
                    required
                    type="password"
                    placeholder="Password"
                    {...register('password')}
                    className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
                />
                </div>
                {errors.password && <span style={{ color: 'red' }}>{errors.password.message}</span>}
            </div>
            <div className="relative mt-[2vw]">
                <button
                type="submit"
                className="rounded-[2vw] md:rounded-[.5vw] overflow-hidden bg-black text-white w-full text-[4vw] md:text-[1.1vw] px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw]"
                disabled={disableSubmit}
                >
                <p>Log In</p>
                </button>
            </div>
            </form>

            <div className="mt-[5vw] md:mt-[2vw]">
            <div className="flex justify-center text-[3.25vw] md:text-[1.1vw] gap-[1.5vw] md:gap-[.5vw]">
                Don’t have an account?
                {signupNav===true?
                <button data-modal-target="signupModal" className="open-modal-button border border-black text-[3vw] md:text-[.85vw] leading-[1] rounded-full px-[2vw] md:px-[.75vw] py-[.75vw] md:py-[.2vw]">
                Sign Up
                </button>: <Link to="/signup"  className="open-modal-button text-[3vw] md:text-[.85vw] leading-[1] rounded-full px-[2vw] md:px-[.75vw] py-[.75vw] md:py-[.2vw]" style={{ border: "1px solid black" }}>
                Sign Up
                </Link>
                }
            </div>
            </div>
            <div className="mt-[3vw] md:mt-[1vw]">
            <div className="flex justify-center text-[3.25vw] md:text-[1.1vw] gap-[.5vw]">
                Forgot Password?
                <Link to="/forgot-password" className="text-[#F14234] open-modal-button" >
                Reset Password
                </Link>
            </div>
            </div>
        </div>
        </div>
    </div>
    );

  }

  export default LoginForm;