import SignupModal from "../components/Landing_Signup"

const SignupForm: React.FC = () => {
  return (
    <SignupModal
      customClassNames={{
        overlay: 'modal-overlay-visible',
        content: 'modal-content-visible',
      }}
      closeButton={false}
      loginNav={false}
    />
  )
}

export default SignupForm;