import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { axiosConfig} from '../axiosConfig';
import logo from "../companylogo.png";


const BookaDemo=()=>{

    type FormValues = {
        firstName: string;
        lastName: string;
        email: string;
        organizationName: string;
        captcha: string
    }
    

    const form = useForm<FormValues>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            organizationName: '',
            captcha: '',
        },
    });

    const { register, handleSubmit, reset, formState:{errors} } = form;
    const navigate = useNavigate();
    const [disableSubmit, setDisableSubmit] = useState(false);
    
    const resetform = () => {
        reset(); // Reset form fields when opening the modal
    };
    const onSubmit = async (data: FormValues) => {
        try {
            setDisableSubmit(true);
            const response = await axiosConfig.post('/api/book_a_demo', {
                    "fname": data.firstName,
                    "lname": data.lastName,
                    "email": data.email,
                    "organisation": data.organizationName,
                }
            );
            if (response.data.status === "SUCCESS") {
                setDisableSubmit(false);
                navigate('/book-demo-success',{state:'demo_booked'});
                reset();
            }
        }catch(error: any){
                console.error("An error occurred:", error);
                setDisableSubmit(false);   
        }        
    };
    return (
        <div id="bookModal" className="modal-overlay overflow-y-scroll !items-start hidden">
        <div className="modal-background"></div>
        <div className="modal-content bg-white rounded-[3vw] md:rounded-[.85vw] px-[4vw] md:px-[2.5vw] py-[5vw] md:py-[2.5vw] w-[90vw] md:w-[37vw] relative my-[40vw] md:my-[2vw]">
    <button onClick={resetform} data-modal-close="bookModal" className="close-modal-button absolute top-[2vw] right-[2vw] md:top-[1vw] md:right-[1vw]">
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
            <path fill="#000000" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" />
        </svg>
    </button>

    <div >
        <div className="flex justify-center">
            <img src={logo} className="w-[25vw] md:w-[10vw]" alt="Logo"/>
        </div>
        <div className="mt-[6vw] md:mt-[3vw]">
            <p className="text-[5vw] md:text-[2.5vw] leading-[1] text-center">Book a Demo</p>
        </div>
 
        <form onSubmit={handleSubmit(onSubmit)} noValidate className="flex flex-col gap-[3vw] md:gap-[1vw] mt-[7vw] md:mt-[4vw]">
            <div className="relative">
                <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                    <input required type="text"  {...register("firstName", { required: "First name is required" })} placeholder="First Name" className="text-[4vw] md:text-[1.1vw] w-full h-full  px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"/>
                    </div>
                    {errors.firstName && (
                     <span style={{ color: 'red' }}>{errors.firstName.message}</span>)}
                
            </div>
            <div className="relative">
                <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                    <input required type="text"  {...register("lastName", { required: "Last name is required" })} placeholder="Last Name" className="text-[4vw] md:text-[1.1vw] w-full h-full  px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"/>
                    </div>
                    {errors.lastName && (
                     <span style={{ color: 'red' }}>{errors.lastName.message}</span>)}
                
            </div>
            <div className="relative">
                <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                    <input required type="email"  {...register("email", { 
                                    required: "Email is required", 
                                    pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message: "Enter a valid email"} })}
                                    placeholder="Email ID" className="text-[4vw] md:text-[1.1vw] w-full h-full  px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"/>
                                     </div>
                                    {errors.email && (
                                    <span style={{ color: 'red' }}>
                                    {errors.email.message}
                                    </span>
                )}
               
            </div>
            <div className="relative">
                <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                    <input required type="text" {...register("organizationName", { required: "Organization name is required" })}
                    placeholder="Organization Name" className="text-[4vw] md:text-[1.1vw] w-full h-full  px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"/>
                     </div>
                     {errors.organizationName && (
                     <span style={{ color: 'red' }}>{errors.organizationName.message}</span>)}
            </div>
            <div className="relative mt-[5vw] md:mt-[2vw]">
                <button disabled={disableSubmit} className="rounded-[2vw] md:rounded-[.5vw] overflow-hidden bg-black text-white w-full text-[4vw] md:text-[1.1vw] px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw]">
                    <p>Submit</p>
                </button>
            </div>
        </form>
    </div>
</div>
    </div>


    );
}

export default BookaDemo;